import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import AOS from "aos";
import { Link } from "react-router-dom";
import axios from "axios";
import "aos/dist/aos.css"; // You can also use <link> for styles
// ..
AOS.init();

const Header = () => {
  const [inventory, sethowmany] = useState([]);
  const [bordercount, setbrokercount] = useState([]);
  const [totalVisits, settotalvisits] = useState([]);
  const [product, setproduct] = useState([]);

  useEffect(() => {
    fetchData();
    Sliders();
  }, []);

  const Sliders = async () => {
    try {
      const response = await axios.post(
        "https://bookmyqrcode.com/home_rental/api/api.php",
        {
          method: "getData",
          table: "sliders",
          column: "CategoryId",
          value: "0",
        }
      );

      // console.log("dataSlider", response.data.data);

      // Filter sliders with slider_type "home"

      setproduct(response.data.data);
    } catch (error) {
      console.error("Error fetching sliders:", error);
    }
  };

  const fetchData = async () => {
    const loader = (
      <iframe
        style={{ height: "50px", maxWidth: "100%" }}
        className="iframeofloader"
        src="https://lottie.host/embed/34954852-e7d3-47d6-8646-746d7cad5185/WPUZvPPviT.json"
      ></iframe>
    );

    settotalvisits(loader);
    sethowmany(loader);
    setbrokercount(loader);

    const data = await axios.post(
      "https://bookmyqrcode.com/home_rental/api/api.php",
      {
        method: "getDataInventory",
      }
    );
    settotalvisits(data.data.ip_count);
    sethowmany(data.data.data.length);
    setbrokercount(data.data.broker_count);
    // setBrokerName()
    // console.log("brokercount", data.data.broker_count);
  };

  return (
    <div className="text-white">
      <div id="carouselExample" className="carousel slide">
        <div className="carousel-inner">
          <div className="moileornots">
            {product.map((img, index) => (
              <div
                style={{ height: "30rem" }}
                className={`carousel-item ${index === 0 ? "active" : ""}`}
                key={index}
              >
                <img
                  className="sliderimg"
                  src={"https://bookmyqrcode.com/home_rental/" + img.image}
                  alt={`Slide ${index}`}
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    minHeight: "100%",
                  }}
                />
              </div>
            ))}
          </div>
          <div className="container-fluid  headerhead p-1">
            <div className="header">
              <div className="nav container-fluid">
                <nav className="navbar navbar-expand-lg container-fluid">
                  <div className="col-10">
                    <div className="tooglerdiv">
                      <img
                        src="Assets/image/toogle.png"
                        className="navbar-toggler"
                        alt=""
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasDarkNavbar"
                      />
                    </div>

                    <div
                      className="offcanvas offcanvas-start  bg-dark"
                      tabindex="-1"
                      id="offcanvasDarkNavbar"
                      aria-labelledby="offcanvasDarkNavbarLabel"
                    >
                      <div className="offcanvas-body ">
                        <div className="logos">
                          <div className="offcanvas-header ">
                            <h5
                              className="offcanvas-title"
                              id="offcanvasDarkNavbarLabel"
                            ></h5>
                            <svg
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              fill="currentColor"
                              className="bi bi-x-circle-fill "
                              viewBox="0 0 16 16"
                              id="headerclosebutton"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                            </svg>
                          </div>
                        </div>

                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
                          <NavLink to={"/"}>
                            <li className="nav-item">
                              <a
                                className="nav-link active text-white"
                                aria-current="page"
                              >
                                Home
                              </a>
                            </li>
                          </NavLink>
                          <NavLink to={"/aboutus"}>
                            <li className="nav-item">
                              <a className="nav-link active text-white">
                                About{" "}
                              </a>
                            </li>
                          </NavLink>
                          <NavLink to={"/Reachus"}>
                            <li className="nav-item">
                              <a
                                className="nav-link active text-white"
                                aria-current="page"
                              >
                                Reach Us
                              </a>
                            </li>
                          </NavLink>
                          <NavLink to={"/Blog"}>
                            <li className="nav-item">
                              <a
                                className="nav-link active text-white"
                                aria-current="page"
                              >
                                Blog
                              </a>
                            </li>
                          </NavLink>
                        </ul>
                      </div>

                      <div className="d-flex col-md-12 iconsresponsive justify-content-center">
                        <div className="col-md-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-facebook"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                          </svg>
                        </div>

                        <div className="col-md-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-instagram"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                          </svg>
                        </div>

                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-youtube"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-2 d-flex buttondivs">
                    <a href="/Loginpage">
                      <div className="box " style={{ whiteSpace: "nowrap" }}>
                        <span>Post a Property</span>
                        <i></i>
                      </div>
                    </a>
                  </div>
                </nav>
              </div>
            </div>
            <div className="container TopHeader">
              <div
                className="d-flex  section topheaderow  
              justify-content-center
              col-md-12
  "
              >
                <div className="col-md-12 col-12 d-flex  justify-content-evenly">
                  <div className="col-3 Clientslogo  p-0 m-0">
                    <Link to={"/"}>
                      <img
                        className="img-fluid p-0 m-0"
                        src="Assets/image/logo.png"
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="col-3  text-center Clients ">
                    <h2 className="mb-1">{totalVisits}</h2>
                    <h6 className="mb-1"> Visits</h6>
                    <i></i>
                  </div>
                  <div className="col-3 text-center Clients">
                    <h2 className="mb-1">{inventory}</h2>
                    <h6 className="mb-1">Inventory</h6>
                    <i></i>
                  </div>
                  <div className="col-3 text-center Clients ">
                    <h2 className="mb-1">{bordercount}</h2>
                    <h6 className="mb-1  p-2 py-0">Channel Partner</h6>
                    <i></i>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="text-center col-md-12 d-flex flex-column align-items-center justify-content-end mt-4 onlinerental  wanttoremove pt-3"
              style={{ position: "relative", bottom: "0" }}
              data-aos="fade-up"
              data-aos-anchor-placement="bottom-bottom"
              data-aos-duration="1300"
            >
              <h3 style={{ fontWeight: "650" }}>
                Online Rental Search Platform
              </h3>
              <h5
                className="my-2"
                style={{
                  fontWeight: "550",
                  fontSize: "16px",
                  letterSpacing: "0.3px",
                }}
              >
                PG and 1 BHK only choosen for you...
                {/* PG, Flats, Guesthouse, Hotels, Flat mates, and lot more... */}
              </h5>{" "}
            </div>
          </div>
        </div>
        <div className="moileornots">
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExample"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExample"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
