import BackArrow from "./assets/BackArrow.svg";
import { useNavigate } from "react-router-dom";

function PaymentHeader() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div style={{ flex: 1, maxHeight: "fit-content" }}>
      <div
        className="container-fluid py-3 d-flex flex-column justify-content-center align-items-center w-100"
        style={{
          backgroundColor: "#F2F1EF",
          flex: 1,
          maxHeight: "fit-content",
        }}
      >
        <button
          onClick={goBack}
          className="btn btn-light position-absolute Backbtn"
          style={{
            top: "2vh",
            left: "3vw",
            borderRadius: "50%",
            backgroundColor: "transparent",
          }}
        >
          <img
            src={BackArrow}
            alt="Back"
            style={{ width: "3.5vw", height: "3.5vw" }}
          />
        </button>
        <div className="text-center">
          <h2
            className="fw-bold text-warning"
            style={{ fontSize: "clamp(28px, 3vw, 50px)" }}
          >
            Payment and Receive
          </h2>
          <h2
            className="fw-bold text-dark"
            style={{ fontSize: "clamp(20px, 2vw, 40px)" }}
          >
            Owners’ Contact Details
          </h2>
          <p
            className="text-dark mt-2 mx-auto"
            style={{ fontSize: "clamp(14px, 1.5vw, 18px)", maxWidth: "60%" }}
          >
            The user pays ₹99, and after payment, receives the contact details
            of the 3 owners both on the screen and via WhatsApp.
          </p>
        </div>
      </div>

      <p
        className="text-dark py-2 w-100 shadow-sm text-center"
        style={{
          backgroundColor: "#E7A03C14",
          fontSize: "clamp(12px, 2vw, 18px)",
          boxShadow: "rgba(231, 160, 60, 0.14) 0px 5px 14px",
        }}
      >
        Payment and Receive Owners’ Contact Details | Purchase Summary
      </p>
    </div>
  );
}

export default PaymentHeader;
