import React, { memo, useCallback, useState } from "react";

const Selectoptions = memo(({ options = [], placeholder, className, residentType, setResidentType }) => {
  const [showOptions, setShowOptions] = useState(false);
  const toggleOptions = useCallback(() => {
    setShowOptions((prevState) => !prevState);
  }, []);
  const changeCurrentSelection = useCallback((e) => {
    const data = e.target.innerText;
    setResidentType(data);
    toggleOptions();
  }, [setResidentType, toggleOptions]);
  return (
    <div
      className={`position-relative ${className} p-2 rounded-4 border-warning`}
      style={{ border: "2px solid yellow" }}
    >
      <div
        onClick={toggleOptions}
        className="d-flex gap-2 align-items-center justify-content-between text-center cursor-pointer"
      >
        {residentType ? residentType : placeholder}
        <div>
          <svg
            style={{
              rotate: showOptions ? "-90deg" : "90deg",
              transition: "all 300ms",
              marginRight: 4
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            viewBox="0 0 24 24"
            fill="black"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            className="lucide lucide-play"
          >
            <polygon points="6 3 20 12 6 21 6 3" />
          </svg>
        </div>
      </div>
      <div
        className="position-absolute w-100 rounded-4 border-2 border-warning bg-white shadow-sm"
        style={{
          display: showOptions ? "" : "none",
          top: "120%",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        {options.map((option, index) => (
          <div key={index} className="option p-2 rounded-4 text-center cursor-pointer" onClick={changeCurrentSelection}>
            {option}
          </div>
        ))}
      </div>
    </div>
  );
});

export default Selectoptions;
