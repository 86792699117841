import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "./Footer";
import { Helmet } from "react-helmet-async";
import { base_url } from "../constants/Service";
import axios from "axios";

const BlogNewsPage = () => {
  const canonicalUrl = `${base_url}/Blognewspage`;
  const [data, setData] = useState("");
  const location = useLocation();
  const url = location.pathname;

  useEffect(() => {
    // Extract numbers from the URL after the last '/'
    const numbers = url.match(/\/(\d+)$/);
  
    if (numbers) {
      // If numbers are found, do something with them
      const extractedNumber = parseInt(numbers[1]);
      // console.log('Extracted numbers:', extractedNumber);
  
      // Set the data only if it's not NaN or undefined
      if (!isNaN(extractedNumber) && extractedNumber !== undefined) {
        setData(extractedNumber);
      }
    }
  }, [url]);
  
  useEffect(() => {
    // Fetch data only if data is a non-empty string
    if (data !== "") {
      fetchData();
    }
  }, [data]);

  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://bookmyqrcode.com/home_rental/api/api.php",
        {
          method: "getData",
          table: "blog",
          column: "blogId",
          value: data.toString() // Ensure data is a string
        }
      );
      setData(response.data.data[0]);
      // console.log("data:", response.data.data[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div>
      <Helmet>
        <title>{data.title_tag}</title>
        <meta name="description" content={data.meta_description} />
        <meta name="keywords" content={data.meta_keywords} />
        <meta name="robots" content={data.meta_rebots} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div className="col-md-12 col-12 text-white  " style={{ height: "500px" }}>
        <div className="badge">{data.blog_date}</div>
        <img
          className='img-fluid col-md-12 col-12 overlay'
          style={{ position: "absolute", top: "0", left: "0", height: "500px", objectFit: "cover" }}
          src={"https://bookmyqrcode.com/home_rental/" + data.image}
          alt=""
        />
        <div className="col-md-12 d-flex justify-content-center align-items-center text-center" style={{ position: "relative", height: "300px" }}>
          <h1 >{data.title}</h1>
        </div>
      </div>
      <div className="col-md-12 col-12  p-4 " >
        <div className="col-md-12 p-4 ">
          <p className="text-black text-start">{data.short_desc}</p>
          <div dangerouslySetInnerHTML={{ __html: data.long_desc ? data.long_desc.replace(/\n|\r/g, ' ') : '' }} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogNewsPage;
