import React, { useState, createContext } from "react";

export const Filter = createContext();

const FilterContext = ({ children }) => {
    const [videoFilterData, setVideoFilterData] = useState([]);
  return <Filter.Provider value={{videoFilterData, setVideoFilterData}} >{children}</Filter.Provider>;
};

export default FilterContext;
