import React, { memo } from "react";
import bgimg from "../../assets/images/heroimg.png";

const Hero = memo(({ title, subTitle }) => {
  return (
    // <section id="hero" className="h-[500px] font-bold text-white flex flex-col items-center justify-center"  style={{ backgroundImage: `url(${bgimg})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center"}}>
    //   <h1 style={{fontSize: "clamp(24px, 8vw, 64px)"}}>{title}</h1>
    //   <h3 className="font-semibold" style={{fontSize: "clamp(14px, 4vw, 24px)"}}>{subTitle}</h3>
    // </section>
    <section
      id="hero"
      className="d-flex flex-column justify-content-center align-items-center text-white"
      style={{
        height: "500px",
        backgroundImage: `url(${bgimg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <h1
        className="font-weight-bold"
        style={{ fontSize: "clamp(24px, 8vw, 64px)" }}
      >
        {title}
      </h1>
      <h3
        className="font-weight-semibold"
        style={{ fontSize: "clamp(14px, 4vw, 24px)" }}
      >
        {subTitle}
      </h3>
    </section>
  );
});

export default Hero;
