import React, { memo, useCallback, useState } from "react";

const Question = memo(({ questionList = [], title, subTitle }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const changeIndex = useCallback((e) => {
    const id = e.target.id;
    setCurrentQuestion(id);
  }, []);
  return (
    <div className="container mx-auto px-3 my-5">
      <div className="row g-4">
        <div className="col-sm-4 d-none d-sm-block max-height-890 overflow-auto">
          {questionList.map((question, index) => (
            <h5
              key={index}
              className={`cursor-pointer transition-all duration-300 p-3 ${
                index == currentQuestion && "rounded"
              }
          `}
              id={index}
              style={
                index == currentQuestion ? {
                  backgroundColor: "#F9FAFB",
                  color: "#E7A03C",
                }:{}
              }
              onClick={changeIndex}
            >
              {index + 1}. {question.question}
            </h5>
          ))}
        </div>
        <div className="col-sm-8">
          <header>
            <h1 className="fw-semibold">{title}</h1>
            <h4>{subTitle}</h4>
          </header>
          <div className="questionSection d-none d-sm-block mt-4">
            <h3 className="question">
              {Number(currentQuestion) + 1}.{" "}
              {questionList[currentQuestion].question}
            </h3>
            <div className="answer lead">
              {typeof questionList[currentQuestion].answer == "string" ? questionList[currentQuestion].answer :  questionList[currentQuestion].answer.map((answer, index) => <p key={index}>{answer}</p>)}
            </div>
          </div>
        </div>
        <div className="questionSection d-sm-none">
          {questionList.map((question, index) => (
            <div key={index} className="mb-4">
              <h3 className="question display-6 fw-bold">
                {index + 1}. {question.question}
              </h3>
              <div className="answer lead">{typeof question.answer == "string" ? question.answer : question.answer.map((answer, index) => <p key={index}>{answer}</p>)}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default Question;
