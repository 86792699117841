import React, { memo } from "react";
import avnish from "../assets/images/Avnish.png";

const FounderSection = memo(() => {
  return (
    <section id="founder" className="d-flex gap-5 flex-column py-5 mx-auto" style={{maxWidth: 1063}}>
      <div className="fw-bolder text-center" style={{fontSize: "clamp(30px, 5vw, 50px)"}}>Who's Behind This?</div>
      <div
        className="d-flex gap-5 align-items-center flex-sm-row flex-column px-5"
      >
        <div
          className="left p-4 rounded-2 aboutDetail"
          style={{ boxShadow: "0 4.38px 23.22px 0 rgba(0, 0, 0, 0.17)", }}
        >
          <img
            src={avnish}
            alt="Avnish Yadav (Founder)"
            className="w-100"
          />
        </div>
        <div className="right d-flex flex-column gap-2 text-sm-start text-center aboutDetail">
          <div>
            <div className="fw-semibold" style={{lineHeight: "120%", fontSize: "clamp(30px, 5vw, 50px)"}}>Avnish Yadav</div>
            <h3 className="text-danger fw-semibold">
              Founder of rentout.homes
            </h3>
          </div>
          <p style={{lineHeight: "160%", letterSpacing: 0.8}}>
            Total of 22 years of experience in which past 10 years are in
            financial products and 12 years in Real Estate, (6 years of direct
            sales experience and 16 years in team handling and Management
            profiles){" "}
            <strong className="text-black">
              with a strong performance background in every role held in
              companies like ICICI Home Loans, Birla Home Loans, CitiFinancial,
              ICICI Prudential Life Insurance, Aviva Life Insurance, Agni
              Property, HDFC Realty, Cushman & Wakefield, Colliers International
              & Housingman.
            </strong>
          </p>
        </div>
      </div>
    </section>
  );
});

export default FounderSection;
