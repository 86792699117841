import React from "react";
import Aboutushome from "./Aboutushome";
import Header from "./Header";
import Aboutusimage from "./Aboutusimage";
import Footer from "./Footer/Footer";
import Teammember from "./Teammember";
import { Helmet } from "react-helmet-async";
import { base_url } from "../constants/Service";
import FounderSection from "./FounderSection";
const Aboutus = () => {
  const pageTitle = "About Us";
  const canonicalUrl = `${base_url}/aboutus`;
  return (
    <div id="aboutSection">
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Header />
      <Aboutushome />
      <Aboutusimage />
      <FounderSection />
      <Teammember />
      <Footer />
    </div>
  );
};

export default Aboutus;
