import React, { memo } from "react";
import Hero from "../Hero/Hero";
import Question from "../Questions/Question";
import Note from "../Note/Note";
import Footer from "../Footer/Footer";

const termsAndConditions = [
  {
    question: "Defination",
    answer:
      'These Terms & Conditions ("Terms") govern your use of Rentout.Homes and the services we offer. By using our Site and services, you agree to these Terms. If you do not agree, please refrain from using our services.',
  },
  {
    question: "Eligibility",
    answer:
      "You are at least 18 years of age. You have the legal authority to enter into binding contracts. You will use our services in compliance with these Terms and applicable laws.",
  },
  {
    question: "Account Registration",
    answer:
      "You may be required to create an account to use certain features of the Site. You agree to: Provide accurate and complete information. Keep your account information up to date. Maintain the security of your account by keeping your password confidential.",
  },
  {
    question: "Rental Listings and Transactions",
    answer:
      "Rentout.Homes provides a platform for users to list and rent properties. We are not responsible for the actual transactions between renters and property owners. Property owners must ensure that listings are accurate and not misleading. Renters must agree to the terms of the rental agreement before completing a booking.",
  },
  {
    question: "Fees and Payments",
    answer:
      "Renters agree to pay all fees associated with the rental as specified in the listing. Property owners are responsible for setting the rental price and managing payments. Any disputes regarding payments must be resolved directly between the property owner and the renter.",
  },
  {
    question: "Prohibited Activities",
    answer:
      "You agree not to: Post false or misleading information. Use the platform for any illegal or unauthorized purpose. Attempt to hack, disrupt, or interfere with the platform.",
  },
  {
    question: "Intellectual Property",
    answer:
      "All content on the Site, including text, graphics, logos, and software, is the property of Rentout.Homes or its licensors. You may not use any content without prior written permission.",
  },
  {
    question: "Limitation of Liability",
    answer:
      "Rentout.Homes is not liable for any direct, indirect, incidental, or consequential damages arising from your use of the platform or any rental agreement.",
  },
  {
    question: "Termination",
    answer:
      "We reserve the right to terminate or suspend your account at our discretion, without notice, if you violate these Terms or engage in activities that harm the platform.",
  },
  {
    question: "Changes to Terms",
    answer:
      "We may update these Terms from time to time. Your continued use of the Site will constitute acceptance of any revised Terms.",
  },
  {
    question: "Contact Us",
    answer:
      "For any questions or concerns about these Terms & Conditions, please contact us at [avnishyadav@rentout.homes].",
  },
];

const impPoints = [
  "The subscription fee is non-refundable, regardless of whether you successfully contact the property owner or secure a rental.",
  "Once the subscription fee is paid and owner contact details are provided, no refund will be issued under any circumstances.",
  "We recommend carefully reviewing listings before purchasing a subscription.",
];
const TandC = memo(() => {
  return (
    <div>
      <Hero title="Terms And Conditions" subTitle="Home/Terms And Conditions" />
      <Question
        questionList={termsAndConditions}
        title="Terms And Conditions"
        subTitle="Effective Date: 01-06-2022"
      />
      <Note>
        <h1 className="fw-bold display-4">Important</h1>
        <ul>
          {impPoints.map((point, index) => (
            <li className="lead" key={index}>
              {point}
            </li>
          ))}
        </ul>
      </Note>
      <Footer />
    </div>
  );
});

export default TandC;
