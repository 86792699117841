import React, { memo } from "react";
import Hero from "../Hero/Hero";
import Question from "../Questions/Question";
import Note from "../Note/Note";
import Footer from "../Footer/Footer";

const privacyPolicy = [
  {
    question: "Definitions",
    answer:
      'Rentout.Homes ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website [www.rentout.homes] (the “Site”) and use our services.',
  },
  {
    question: "Information We Collect",
    answer:
      "We may collect information about you in a variety of ways, including Personal Data (identifiable information such as your name, email address, phone number, and payment details), Rental Details (property information, rental agreements, and transaction history), and Usage Data (data collected automatically such as your IP address, browser type, and usage patterns).",
  },
  {
    question: "How We Use Your Information",
    answer:
      "We use your personal information to facilitate property rental transactions, provide customer support and respond to inquiries, process payments and rental agreements, improve our services and website performance, and communicate with you regarding updates or promotional offers.",
  },
  {
    question: "Sharing Your Information",
    answer:
      "We do not sell or rent your personal information to third parties. However, we may share your information with Service Providers (third-party vendors who assist in delivering our services), Law Enforcement (if required by law or in response to legal requests), and Business Transfers (in the event of a merger, acquisition, or sale of company assets).",
  },
  {
    question: "Cookies and Tracking Technologies",
    answer:
      "We may use cookies and similar tracking technologies to collect usage data. You can disable cookies through your browser, but some features of our Site may be unavailable if you do so.",
  },
  {
    question: "Data Security",
    answer:
      "We use industry-standard security measures to protect your information. However, no method of transmission over the internet is completely secure, and we cannot guarantee absolute security.",
  },
  {
    question: "Your Privacy Rights",
    answer:
      "You have the right to access, correct, or delete your personal information, opt out of marketing communications, and request the restriction or objection to the processing of your personal data. To exercise these rights, please contact us at [avnishyadav@rentout.homes].",
  },
  {
    question: "Changes to This Policy",
    answer:
      "We may update this Privacy Policy from time to time. Changes will be posted on this page, and the 'Effective Date' will be updated.",
  },
  {
    question: "Contact Us",
    answer:
      "If you have any questions or concerns about this Privacy Policy, please contact us at [avnishyadav@rentout.homes].",
  },
];

const impPoints = [
  "The subscription fee is non-refundable, regardless of whether you successfully contact the property owner or secure a rental.",
  "Once the subscription fee is paid and owner contact details are provided, no refund will be issued under any circumstances.",
  "We recommend carefully reviewing listings before purchasing a subscription.",
];

const PrivacyPollicy = memo(() => {
  return (
    <div>
      <Hero title="Privacy Policy" subTitle="Home/Privacy and Policy" />
      <Question
        questionList={privacyPolicy}
        title="Privacy and Policy"
        subTitle="Effective Date: 01-06-2022"
      />
      <Note>
        <h1 className="fw-bold display-4">Important</h1>
        <ul>
          {impPoints.map((point, index) => (
            <li className="lead" key={index}>
              {point}
            </li>
          ))}
        </ul>
      </Note>
      <Footer />
    </div>
  );
});

export default PrivacyPollicy;
