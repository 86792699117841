import React from "react";

const Shimmerui = () => {
  return (
    <div className="container">
      <div className="row videosform section col-md-12">
        <div className="col-md-3 my-5  " id="MobileForm">
          <div className="form col-md-12 ">
            <div id="mobileinner">
             
            </div>
            <div id="" className="">
        

              <div className=" col-md-12 px-2 maindiv towmaindiv ">


         
              </div>
              <div className="container-fluid d-flex justify-content-between maxheight shimmeruismall " style={{ maxHeight: "15px" }}>

</div>
            </div>


         
            <div className="container px-2 maindiv shimmeruismall my-3"  >
              
              </div>
            <div className="container px-2 maindiv shimmeruismall my-1"  >
              
              </div>

              <div className="container-fluid d-flex justify-content-between maxheight shimmeruismall my-2" style={{ maxHeight: "15px" }}>

                </div>
                <div className="d-flex justify-content-center align-items-center my-1">
                <div className="buttonssshimmerui d-flex my-3">
                <button></button>
              </div>{" "}
              </div>{" "}

          </div>
        </div>
        <div className="col-md-9 " id="mobilediv">
          <div className="row">
            <div className="col-8 d-flex align-items-center">
              <h3 className="text-start Heading"></h3>
            </div>
            <div className="col-4">
              {" "}

            </div>
          </div>

          <div className="d-flex  row justify-content-start  col-12 videobar">
            <div
              className="col-3 my-1"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <div className="shimmerui"></div>
            </div>
            <div
              className="col-3 my-1"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <div className="shimmerui"></div>
            </div>
            <div
              className="col-3 my-1"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <div className="shimmerui"></div>
            </div>
            <div
              className="col-3 my-1"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <div className="shimmerui"></div>
            </div>
            <div
              className="col-3 my-1"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <div className="shimmerui"></div>
            </div>
            <div
              className="col-3 my-1"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <div className="shimmerui"></div>
            </div>
            <div
              className="col-3 my-1"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <div className="shimmerui"></div>
            </div>
            <div
              className="col-3 my-1"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <div className="shimmerui"></div>
            </div>
            <div
              className="col-3 my-1"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <div className="shimmerui"></div>
            </div>
          </div>


        </div>
      </div>
    </div>
  );
};

export default Shimmerui;
