import React from 'react'

const Aboutushome = () => {
  return (
    <div className='container Aboutushome' >
      <div className='d-flex justify-content-center m-5 flex-column align-items-center '>
        <div className="col-md-9 d-flex justify-content-center  flex-column align-items-center text-center">
          <h2 style={{ fontWeight: "650" }}>About Us</h2>
          <p style={{ fontWeight: "600", fontSize: "13px", color: "grey" }} className='text-start'>Welcome to Rentout.Homes, your ultimate destination for hassle-free and efficient
            rental solutions. We specialize in catering to a diverse range of housing needs,
            including rental accommodation, PG (Paying Guest) options, coliving spaces, 1BHK to 4BHK apartments, and even the perfect flatmate match.
            Additionally, we extend our services to those seeking short-term stays in guest houses, bed and breakfasts, and hotels.</p>
        </div>
      </div>
    </div>
  )
}

export default Aboutushome
