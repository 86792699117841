import PaymentHeader from "./PaymentHeader";
import PaymentContent from "./PaymentContent";
import OtherCourseCard from "./OtherCourseCard";
import { useCallback, useContext, useEffect, useState } from "react";
import PaymentForm from "./PaymentForm";
import { wishlistItemCount } from "../../Contexts/WishListContext";
import { Filter } from "../../Contexts/FilterContext";
import CartModel from "../CartModel";
import { Link } from "react-router-dom";

function CartPage() {
  const { data } = useContext(wishlistItemCount);
  const { videoFilterData } = useContext(Filter);
  const [otherRentals, setOtherRentals] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  const [listCategory, setListCategory] = useState([]); //tell which list to be scrolled within the model (otherRental or wishlist)
  const [selectedItem, setSeletedItem] = useState(null);
  const toggleModel = useCallback(
    (e) => {
      const idAndCategory = e.currentTarget.id.split(" ");
      setOpenModel((prevState) => !prevState);
      // console.log(idAndCategory);
      setSeletedItem(idAndCategory[0]);
      idAndCategory[1] == "wishlist"
        ? setListCategory(data)
        : setListCategory(otherRentals);
    },
    [data, otherRentals]
  );
  useEffect(() => {
    const ids = Array.from(data).map((item) => item.inventroyId);
    const recomendations = videoFilterData.filter(
      (item) => !ids.includes(item.inventroyId)
    );
    setOtherRentals(recomendations);
  }, [videoFilterData, data]);
  return (
    <>
      {openModel && (
        <CartModel
          item={selectedItem}
          itemCategory={listCategory}
          setOpenModel={setOpenModel}
        />
      )}
      <div
        className="d-flex flex-column gap-4 overflow-md-hidden"
        style={{ overflowY: "auto" }}
      >
        <PaymentHeader />
        <div style={{ flex: 1, maxWidth: 1440, width: "90vw", margin: "auto" }}>
          <div className="row g-4 d-flex align-items-center">
            <div
              className={`${
                data.size > 0 ? "col-md-6" : "col-md-12"
              } col-sm-12 d-flex flex-column order-md-1 order-2 gap-2`}
            >
              <div
                className="shadow-sm rounded p-3 d-flex flex-column"
                id="wishlist"
                style={{
                  height: 250,
                  overflowY: "auto",
                  backgroundColor: data.size > 0 ? "white" : "lightgrey", // Example to change color based on data size
                }}
              >
                {data && data.size > 0 ? (
                  Array.from(data).map((item, index) => (
                    <PaymentContent
                      id={`${index} wishlist`}
                      onClick={toggleModel}
                      key={item.inventroyId}
                      Details={item.title}
                    />
                  ))
                ) : (
                  <div
                    className="text-secondary d-flex justify-content-center align-items-center text-center flex-column"
                    style={{ flex: 1 }}
                  >
                    <div style={{ fontSize: "clamp(24px, 8vw, 42px)" }}>
                      Your RentOut Homes Cart is Empty.
                    </div>
                    <p>
                      Check other Rents below or{" "}
                      <Link to="/">Continue browsing</Link>
                    </p>
                  </div>
                )}
              </div>

              <div className="d-flex flex-column gap-2" style={{ height: 350 }}>
                <p className="h4 fw-bold text-dark text-start">Suggestions:</p>{" "}
                <div
                  className="d-flex gap-3 pt-4 pb-2"
                  style={{ flex: 1, overflowX: "auto", aspectRatio: 1 }}
                >
                  {otherRentals.map((item, index) => (
                    <OtherCourseCard
                      id={`${index} otherRentals`}
                      key={item.inventroyId}
                      item={item}
                      onClick={toggleModel}
                    />
                  ))}
                </div>
              </div>
            </div>
            {data.size > 0 && (
              <div className="col-md-6 d-flex justify-content-center align-items-center order-md-2 order-1">
                <PaymentForm recomendations={otherRentals} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CartPage;
