import React, { useCallback, useContext, useState } from "react";
import { wishlistItemCount } from "../../Contexts/WishListContext";
import Selectoptions from "../Selectoptions";

const ResidentTypes = ["Family", "Bachelor"];

function sendMessage(){
  console.log("Sending message");
}

function PaymentForm({recomendations = []}) {
  console.log(recomendations)
  recomendations.forEach(element => {
    console.log(element)
  });
  const { data } = useContext(wishlistItemCount);
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [workPlace, setWorkPlace] = useState("");
  const [residentType, setResidentType] = useState(null);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = useCallback(() => {
    setIsChecked((prevState) => !prevState);
  }, []);

  const isValidIndianPhoneNumber = useCallback((number) => {
    // Remove any non-digit characters (optional)
    const cleanedNumber = number.replace(/\D/g, "");

    // Regular expression to check the validity of the Indian phone number
    const regex = /^(?:\+91|91|0)?[789]\d{9}$/;

    return regex.test(cleanedNumber);
  }, []);

  //function to load razorpay sript in frontend
  function loadRazorPay() {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    document.body.appendChild(script);
    script.onload = () => {
      var Razorpay = window.Razorpay;
      handleSubmit(Razorpay);
    };
  }

  async function handleSubmit(Razorpay) {
    const amount = data.size * 33;
    try {
      const response = await fetch(
        `http://knwehbs.localto.net:1390/razorpay/order`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ amount: amount }),
        }
      );

      const result = await response.json();
      console.log("Success:", result);
      sendMessage();

      if (response.ok) {
        var options = {
          key_id: "rzp_test_w1PnHafmCNsrDy",
          amount: `${result.amount}`,
          currency: "INR",
          name: "Acme Corp",
          description: "Test Transaction",
          image: "https://example.com/your_logo",
          order_id: `${result.id}`,
          handler: function (response) {
            alert("Payment successfull!");
          },
          prefill: {
            name,
            // email: "jiteshvarade1@gmail.com",
            contact: number,
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
        };
        // console.log(options);

        var rzp1 = new Razorpay(options);
        rzp1.open();

        rzp1.on("payment.failed", function (response) {
          alert(response.error.code);
          alert(response.error.description);
          alert(response.error.source);
          alert(response.error.step);
          alert(response.error.reason);
          alert(response.error.metadata.order_id);
          alert(response.error.metadata.payment_id);
        });
      }

      console.log(result);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  return (
    <>
      <form
        className="bg-white shadow rounded d-flex gap-3 align-items-center flex-column"
        style={{
          maxWidth: "554px",
          minWidth: "333px",
          height: "fit-content",
          padding: "1.5rem",
        }}
      >
        <p className="text-danger bg-warning bg-opacity-10 p-3 w-100 rounded-2">
          Note: Minimum Orders 3
        </p>
        <div
          className="py-2 row row-cols-2 gx-4 gy-4 align-items-stretch justify-content-stretch"
          style={{ maxWidth: 500 }}
        >
          <div>
            <input
              type="text"
              placeholder="Name"
              className="border-2 border-warning p-2 rounded-4  w-100 h-100"
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{ outline: "none" }}
              required
            />
          </div>
          <div>
            <input
              type="number"
              placeholder="Contact Number"
              className="border-2 border-warning p-2 rounded-4  w-100 h-100"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              style={{ outline: "none" }}
              required
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="Company Name"
              className="border-2 border-warning p-2 rounded-4  w-100 h-100"
              value={workPlace}
              onChange={(e) => setWorkPlace(e.target.value)}
              style={{ outline: "none" }}
              required
            />
          </div>
          <div>
            <Selectoptions
              placeholder="Resident Type"
              options={ResidentTypes}
              residentType={residentType}
              setResidentType={setResidentType}
            />
          </div>
        </div>
        <div className="border-bottom w-100">
          <p className="d-flex justify-content-between fw-bolder">
            <span>Total interests</span> <span>{data.size}</span>
          </p>
          <p className="d-flex justify-content-between fw-bolder">
            <span>Total Amount</span>{" "}
            <span className="text-success" style={{ letterSpacing: 2 }}>
              ₹33 X {data.size} = ₹{data.size * 33}{" "}
            </span>
          </p>
        </div>

        <p className="d-flex justify-content-between w-100 fw-bolder">
          <span>To Pay</span>{" "}
          <span className="text-success" style={{ letterSpacing: 2 }}>
            ₹{data.size * 33}
          </span>
        </p>
        <div className="d-flex align-items-center gap-3">
          <div
            className="px-1 d-flex justify-content-center align-items-center rounded cursor-pointer"
            onClick={handleCheckboxChange}
            style={{
              width: 35,
              backgroundColor: isChecked ? "red" : "white",
              border: "2px solid red",
              aspectRatio: 1,
            }}
          >
            {isChecked && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                viewBox="0 0 24 24"
                fill="none"
                stroke="white"
                stroke-width="8"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-check"
              >
                <path d="M20 6 9 17l-5-5" />
              </svg>
            )}
          </div>
          <p className="mt-2">
            To assist in your property search, may I have your permission to
            share your details with relevant parties.
          </p>
        </div>
        <button
          className="btn btn-danger d-flex justify-content-center w-100 py-2 gap-2 rounded-pill align-items-center"
          disabled={!isChecked || data.size < 3}
          onClick={(event) => {
            event.preventDefault();
            loadRazorPay(data.size * 33);
          }}
        >
          Proceed to Payment | ₹{data.size * 33}
        </button>
        {/* <p className="d-flex justify-content-between fw-bolder">
          Get Owner’s Details only at ₹33
        </p> */}
      </form>
    </>
  );
}

export default PaymentForm;
