
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Shimmerui from "./Shimmerui";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import AOS from "aos";
import Select from "react-select";
import { BrowserRouter as Router, Route } from "react-router-dom";
import $ from 'jquery';
import "aos/dist/aos.css"; // You can also use <link> for styles
import InfiniteScroll from 'react-infinite-scroller';
import Loader from "./Loader";
import { useLocation } from "react-router-dom";
import { useParams } from 'react-router-dom';
import ContactusinForm from "./ContactusinForm";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Content from "./Content";
// ..
// ..
// ..
// ..
AOS.init();
const DummyProduct = (props) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [videodata, setVideoData] = useState([]);

  const [videoFilterData, setVideoFilterData] = useState([]);
  const [visibleData, setVisibleData] = useState(videoFilterData.slice(0, 5)); // Initial visible data


  const [CityData, setCityData] = useState([]);
  const [modalopen, setmodalopen] = useState(false);
  const [selectadedata, setselectadedata] = useState([]);
  const [selectedCityId, setSelectedCityId] = useState("4"); // Initialize with an empty string
  const [SelectedAreaId, setSelecteAreaId] = useState(""); // Initialize with an empty string
  const [Areadata, setAreadata] = useState([]);
  const [category, setcategory] = useState([]);
  const [categoryIds, setcategoryIds] = useState([]);
  const [minAmount, setMinAmount] = useState("500");
  const [maxAmount, setMaxAmount] = useState("500000");
  const [stepAmount, setStepAmount] = useState("5000");
  const [propsid, setPropsId] = useState(""); // Initialize with the value of props.selectedId
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [Results, setResults] = useState([]);
  const [howmany, sethowmany] = useState([]);
  const [value, setValue] = React.useState([]);
  const [name, setname] = useState([]);
  const [number, setnumber] = useState([]);
  const [NameOfCategoerys, setNameOfCategoerys] = useState([]);
  const [submitButtonColor, setSubmitButtonColor] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [Brokerid, setBrokerid] = useState([]);
  const [defname, setdefaululname] = useState([]);
  const [defid, setdefaululid] = useState([]);
  const [otherContent,setOtherContent] = useState('')
  const [scrollBtn,setScrollBtn] = useState(false)
  const navigation = useNavigate();


  const [product, setproduct] = useState([])


  const footerRef = useRef(null);

  // Function to handle scroll to footer
  const scrollToFooter = () => {
    if(scrollBtn == false){
    footerRef.current.scrollIntoView({ behavior: 'smooth' });
    setScrollBtn(true)
    }
    else{
      window.scrollTo(0, 0)
      setScrollBtn(false)
    }
  };

  useEffect(() => {
    
    if(defid.length !== 0){
      Sliders();
    }
  }, [defid])


  const Sliders = async () => {
    try {
      const response = await axios.post("https://bookmyqrcode.com/home_rental/api/api.php", {

      method: "getData",
      table: "sliders",
      column : "CategoryId",
      value : `${defid}`

      });

      // console.log("dataSlider", response);

      // Filter sliders with slider_type "home"

      setproduct(response.data.data);
      setOtherContent(response.data.other_content)
      

    } catch (error) {
      console.error("Error fetching sliders:", error);
    }
  };

  const [PerDay, Setperday] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { categoryId } = useParams();

  // console.log("categoryId", categoryId.replace(/-/g, " "));


  useEffect(() => {

    fetchDataCity();
    fetchDataArea();
    // fetchFillterbySearch();
  }, []);


  useEffect(() => {
    fetchcategorys();
  }, [categoryId]);


  const fetchcategorys = async () => {
    const data = await axios.post(
      "https://bookmyqrcode.com/home_rental/api/api.php",
      {
        method: "getData",
        table: "categories",
        column: "status",
        value: "1",
        orderColumn: "sort_order",
        ordervalue: "Desc",
      }
    );
    const filteredData = data?.data?.data;

    // Remove the first element from the filtered data

    setcategory(filteredData);
    // console.log("categoryId", categoryId.toUpperCase());

    const categoryItem = filteredData.find(item => item.name.toLowerCase() === categoryId.replace(/-/g, " "));
    const categoryIds = categoryItem ? categoryItem.categoryId : null;

    if(categoryIds != null){
    setdefaululid(categoryIds)
    // console.log("categoryItem", categoryItem);


    if (categoryId) {
      // Assuming your URL format is consistent with "nameId" (e.g., "categoryName123")
      const categoryName = categoryId.replace(/-/g, " ")

      setdefaululname(categoryName)

      // Log the category name and ID
      // console.log("Category Name:", categoryName);

      // if(categoryId !== "5-bhk--&-above"){
      //   const categoryItem = filteredData.find(item => item.name.toLowerCase() === categoryId.replace( '-' ," "));
      //   const categoryIds = categoryItem ? categoryItem.categoryId : null;
      //   console.log("categoryItem", categoryItem);
      //   setdefaululid(categoryIds)
      // }
      // else{
      //   categoryId.replace( '5-bhk--&-above' ," 5 bhk & above")
      //   setdefaululid("8")
      // }


    }
  }
  else{
    navigation('/')
  }

  };


  // console.log("defid", defid.length)

  useEffect(() => {
    if (defid.length !== 0) {
      fetchData(defid)
    }

  }, [defid])

  const fetchData = async (catID) => {

    // console.log("catID", catID);

    let id = "";

    if (catID === undefined || catID === "12" || categoryId === undefined) {
      id = "";
    } else {
      id = catID;
    }



    if (id === "10" || id === "11") {
      Setperday(true);
    } else {
      Setperday(false);
    }


    // console.log("id------->", id);
    // console.log("id------->", catID);


    const data = await axios.post(
      "https://bookmyqrcode.com/home_rental/api/api.php",
      {
        method: "getDataInventory",
        categoryId: `${id}`,
      }
    );

    setVideoData(data?.data?.data);
    setVideoFilterData(data?.data?.data);
    // console.log("ss--->", data?.data);
    setNameOfCategoerys(defname);
    // console.log("setVideoFilterData12", data?.data?.data);

    // console.log("Fetch Data Api Calling.....2", catID);


  };


  const [originalButtonColor, setOriginalButtonColor] = useState("");
  
  useEffect(() => {
    // Set the original button color when the component mounts
    setOriginalButtonColor(""); // Set your original color here
  }, []);

  // console.log("NameOfCategoerys", NameOfCategoerys);

  // console.log("Results", Results);

  // console.log("videoFilterDatssa", videoFilterData);

  // console.log("setSelectedCategoryName", selectedCategoryName);

  // console.log("propsid", propsid);

  // console.log("maxAmount", maxAmount);

  const handleCityChange = (selectedOption) => {
    setSelectedCityId(selectedOption.value);
  };

  const handleAreaChange = (selectedOptions) => {
    setSelecteAreaId(selectedOptions.map((option) => option.value));
    // console.log(
    //   "areas",
    //   selectedOptions.map((option) => option.value)
    // );
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      setcategoryIds(selectedOption.value);
      setSelectedCategoryName(selectedOption.label);
    }
  };



  const handleFilter = async () => {
    try {
      // Set propsid to an empty string

      // console.log("111", categoryIds);

      if (selectedCategoryName === "") {

      } else {
        setNameOfCategoerys(selectedCategoryName); // Use selectedCategoryName directly
      }

      // Create the request object
      const request = {
        method: "getDataInventory",
        categoryId: categoryIds === "12" ? "" : `${categoryIds}`,
        areaId: `${SelectedAreaId}`,
        rent: `${value[0]},${value[1]}`,
        cityId: `${selectedCityId}`,
      };
      // console.log("handleFilter", request);
      // Make the API call
      const response = await axios.post(
        "https://bookmyqrcode.com/home_rental/api/api.php",
        request
      );
      // console.log("selectedCategoryName", selectedCategoryName);

      // Always update the category name, regardless of the API response

      // Check the response status
      if (response.data.status === "Success") {
        // Update state with the received data
        setVideoFilterData(response.data.data);
        sethowmany(response.data.data.length);
      } else if (response.data.status === "false") {
        // Handle the case when no data is found
        sethowmany("No");
        setVideoFilterData([]);
        toast.error("No Data Found", {
          position: "bottom-right",
          autoClose: 4988,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      console.error("Error--->", error);
      // Handle the error, e.g., show an error message.
    }
  };

  const fetchDataCity = async () => {
    const CityData = await axios.post(
      "https://bookmyqrcode.com/home_rental/api/api.php",
      {
        method: "getData",
        table: "city",
        column: "status",
        value: "1",
      }
    );

    setCityData(CityData.data.data);
    // console.log("DataShows", CityData.data.data);
  };

  const fetchDataArea = async () => {
    const Areadatas = await axios.post(
      "https://bookmyqrcode.com/home_rental/api/api.php",
      {
        method: "getData",
        table: "area",
        column: "cityId,status",
        value: `${selectedCityId},1`,
        orderColumn: "sort_order",
        orderValue: "asc",
      }
    );

    if (Areadatas.status === "false") {
      alert("no");
    } else {
      setAreadata(Areadatas.data.data);
      // console.log("DataShows", Areadatas.data.data);
    }
  };



  useEffect(() => {
    fetchDataArea();
  }, [selectedCityId]);

  useEffect(() => {
    if (categoryIds.length > 0) {
      const selectedCategoriesData = category.filter((cat) =>
        categoryIds.includes(cat.categoryId)
      );

      if (selectedCategoriesData.length > 0) {
        const allMaxValues = selectedCategoriesData.map((cat) =>
          parseFloat(cat.max_amount)
        );
        const allMinValues = selectedCategoriesData.map((cat) =>
          parseFloat(cat.min_amount)
        );

        const maxAmount = Math.max(...allMaxValues);
        const minAmount = Math.min(...allMinValues);

        // Set default values if minAmount is null or empty
        const finalMinAmount = minAmount || 5000;

        // Set default values if maxAmount is null or empty
        const finalMaxAmount = maxAmount || 500000;

        setValue([finalMinAmount, finalMaxAmount]);
        setMaxAmount(finalMaxAmount);
        setMinAmount(finalMinAmount);

        // console.log("selectedCategoriesData", selectedCategoriesData);
        // console.log("allMinValues", allMinValues);
        // console.log("allMaxValues", allMaxValues);
        // console.log("minAmount", finalMinAmount);
        // console.log("maxAmount", finalMaxAmount);
        // console.log("setValue", categoryIds);
      } else {
        // Handle the case when no categories are selected
        setDefaultValues();
      }
    } else {
      setDefaultValues();
    }
  }, [categoryIds, category]);

  const setDefaultValues = () => {
    // console.log("No categories selected");
  };

  // console.log("selectadedatabr", Brokerid)

  useEffect(() => {
    // Set the default value to [1] if categoryIds is an empty array
    if (categoryIds.length === 0) {
      setcategoryIds(defid);

      // console.log("125", defid);
    }
  }, [defid]);

  const SendData = async () => {
    const request = {
      method: "setData",
      table: "enquiry",
      data: {
        name: `${name}`,
        brokerId: `${selectadedata.brokerId}`,
        number: `${number}`,
        shifting_date: `${selectedDate}`,
        inventroyId: `${selectadedata.inventroyId}`,
      },
    };
    // console.log("requestSendDatass", request);

    if (name.length === 0) {
      toast.error("Enter Name", {
        position: "bottom-right",
        autoClose: 4988,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return; // Exit the function if any field is empty
    }
    if (number.length < 1) {
      toast.error("Enter Number", {
        position: "bottom-right",
        autoClose: 4988,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return; // Exit the function if any field is empty
    } else if (number.length !== 10) {
      toast.error("Enter 10 Digit", {
        position: "bottom-right",
        autoClose: 4988,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return; // Exit the function if any field is empty
    }
    //else if (number.length !== 10) {
    //   toast.error("Enter 10 Digit", {
    //     position: "bottom-right",
    //     autoClose: 4988,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: false,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "dark",
    //   });
    //   return; // Exit the function if any field is empty
    // } 
    else if (
      !(selectedDate instanceof Date) ||
      isNaN(selectedDate.getTime())
    ) {
      toast.error("Enter Date please", {
        position: "bottom-right",
        autoClose: 4988,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {

      const data = await axios.post(
        "https://bookmyqrcode.com/home_rental/api/api.php",
        {
          method: "setData",
          table: "enquiry",
          data: {
            name: `${name}`,
            brokerId: `${selectadedata.brokerId}`,
            number: `${number}`,
            shifting_date: `${selectedDate}`,
            inventroyId: `${selectadedata.inventroyId}`,
          },
        }
      );

      // console.log("dataEnquiry", data)

      $.ajax({
        url: 'https://www.fairpockets.com/ApiIntegration/getdataapi',
        type: 'POST',
        data: {
          apikey: "8880a70789ada62399a7b0d12a774197",
          name: `${name}`,
          mobile: `${number}`,
          email: "", // You may leave it as an empty string if not provided
          project: `${selectadedata.category_name}`,
          source: "4",
          requirement: `<br><b>Inventory Id:</b> ${selectadedata.inventroyId},<br>
     <b>Title:</b> ${selectadedata.title},<br>
       <b>Broker Name:</b> ${selectadedata.broker_name},<br>
   <b>City Name:</b> ${selectadedata.city_name},<br>
 <b>Rent:</b> ${selectadedata.rent},<br>
      <b>Furniture Type:</b> ${selectadedata.furniture_type},<br>
      <b>Amenities:</b> ${selectadedata.admin_remark}`


        },


        dataType: 'json',
        success: function (data) {
          // Handle API response here
          // console.log("12aaa", data);
        },
        error: function (error) {
          // Handle errors here
          console.error('Error:->', error);
        }
      });


      //   const datas = await axios.post(
      //     "https://www.fairpockets.com/ApiIntegration/getdataapi",
      //     {
      //       apikey: "8880a70789ada62399a7b0d12a774197",
      //       name: `${name}`,
      //       mobile: `${number}`,
      //       email: "", // You may leave it as an empty string if not provided
      //       project: `${selectadedata.category_name}` ,
      //       source: "4",
      //       requirement: `<br><b>Inventory Id:</b> ${selectadedata.inventroyId},<br>
      //       <b>Title:</b> ${selectadedata.title},<br>
      //       <b>Area Name:</b> ${selectadedata.area_name},<br>
      //       <b>Broker Name:</b> ${selectadedata.broker_name},<br>
      //       <b>City Name:</b> ${selectadedata.city_name},<br>
      //       <b>Rent:</b> ${selectadedata.rent},<br>
      //       <b>Furniture Type:</b> ${selectadedata.furniture_type},<br>
      //       <b>Amenities:</b> ${selectadedata.admin_remark}`
      // }
      //   )

      //   .catch((err)=>{
      //     console.log('Err Api---->',err)
      //   })

      if (data.data.status === "Success") {
        setSubmitButtonColor("green");
        setFormSubmitted(true);
        toast.success(" Success", {
          position: "bottom-right",
          autoClose: 4988,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",

        });

        setname("");
        setnumber("");
        setSelectedDate(new Date());
        setTimeout(() => {
          setSubmitButtonColor(originalButtonColor);
          setFormSubmitted(false);
        }, 4000);
      } else {
        alert(false);
      }
    }
  };
  // Re-run the effect when modalopen changes

  // Function to open the modal
  const openModal = (data) => {
    setselectadedata(data);
    setmodalopen(true);

    window.history.pushState(null, "", window.location.href);
  };

  const handleModalCloseByButton = () => {
    window.history.back();
    setmodalopen(false);
  };

  // close modal on 'back'
  window.onpopstate = () => {
    window.onpopstate = () => { };
    // window.history.back()
    setmodalopen(false);
  };

  React.useEffect(() => {
    document.body.addEventListener("click", () => {
      setmodalopen(false);
    });
  });

  const updateVisibleData = () => {
    const newVisibleData = videoFilterData.slice(0, 15);
    setVisibleData(newVisibleData);
  };

  useEffect(() => {
    updateVisibleData();
  }, [videoFilterData, /* other dependencies */]);
  ///////////////
  ///////////////
  const loadMore = () => {
    // Simulate loading more data (you should fetch more data from your API here)
    setTimeout(() => {
      const newData = videoFilterData.slice(
        visibleData.length,
        visibleData.length + 10
        
      );
      setVisibleData((prevData) => [...prevData, ...newData]);
    }, 1000);
  };

  ///////////////

  if (videodata.length === 0) {
    return <Shimmerui />;
  }
  return (
    <div>
        <div>

{ !scrollBtn &&
  <button className="fixed-button z-1" onClick={scrollToFooter}>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z"/>
</svg>
</button>
}
{
  scrollBtn &&
  <button className="fixed-button secondfixed-button z-1" onClick={scrollToFooter}>

  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-circle-fill" viewBox="0 0 16 16">
<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z"/>
</svg>
  </button>
}

</div>
    <div className="text-white ">
  
    <div id="carouselExample" className="carousel slide">
  <div className="carousel-inner">
    {product.map((item, index) => (
      <div
        className={`carousel-item ${index === 0 ? "active" : ""}`}
        key={index}
        style={{ position: "relative", height: "350px" }}
      >
     <img
  className="sliderimg"
  src={"https://bookmyqrcode.com/home_rental/" + item.image}
  alt={`Slide ${index}`}
  style={{ height: "350px", objectFit: "cover", width: "100%", position: "relative" }}
/>
<div
  className="dark-overlay"
  style={{
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)" // Adjust opacity as needed
  }}
></div>
        <div
          className="col-md-8  col-12 text-center  position-absolute start-50 translate-middle"
          style={{ top: "50%" }}
        >
          <h2 style={{ fontWeight: "650" }}>{item.category_name}</h2>
          <div dangerouslySetInnerHTML={{__html:item.category_desc}}/>
        </div>
      </div>
    ))}
  </div>
  <div className="moileornot">
    <button
      className="carousel-control-prev"
      type="button"
      data-bs-target="#carouselExample"
      data-bs-slide="prev"
    >
      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      <span className="visually-hidden">Previous</span>
    </button>
    <button
      className="carousel-control-next"
      type="button"
      data-bs-target="#carouselExample"
      data-bs-slide="next"
    >
      <span className="carousel-control-next-icon" aria-hidden="true"></span>
      <span className="visually-hidden">Next</span>
    </button>
  </div>
</div>






    </div>      <div className="container d-flex justify-content-center mt-3">
        {modalopen && (
          <div className="col-md-12 Modals">
            <div
              className="col-md-8 modaldiv"
              onClick={(e) => e.stopPropagation()}


            >
              <div className="">
                <div className="card">
                  {selectadedata && (
                    <div className="row g-0 ">
                      <div className="col-md-6 videodiv">
                        <iframe
                          id="videoIframe"
                          title="video"
                          src={selectadedata.video_link}
                          allowFullScreen

                        ></iframe>
                      </div>

                      <div className="col-md-6 p-3 px-4 d-flex flex-column modal-b">
                        <div className="d-flex justify-content-between  justify-content-center my-1">
                          <p style={{ fontWeight: "600" }}>
                            {" "}
                            <strong>{selectadedata.enquiryCount} </strong>
                            Interested

                          </p>
                          <svg
                            onClick={handleModalCloseByButton}
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            color="#A93226"
                            fill="currentColor"
                            className="bi bi-x-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                          </svg>
                        </div>

                        <div className="fills">
                          <div className="d-flex  flex-column justify-content-center col-md-12">
                            <h4 style={{ fontWeight: "650" }}>
                              {selectadedata.title}
                            </h4>
                            {/* <p style={{ fontWeight: "500", fontSize: "13px" }}>
                        {selectadedata.subtitle}
                      </p> */}
                          </div>
                          <div className="d-flex flex-row py-1 justify-content-center col-md-12">
                            <div
                              className="row  p-3 text-center col-md-6 container"
                              style={{ backgroundColor: "#ebedef" }}
                            >
                              <h5 style={{ fontWeight: "650" }}>Channel Partner</h5>{" "}
                              <p
                                style={{ fontWeight: "500", fontSize: "13px" }}
                                className="mb-1"
                              >
                                {selectadedata.broker_name}
                              </p>
                            </div>

                            <div className="row bg-white p-3 text-center col-md-6 container">
                              <h5 style={{ fontWeight: "650" }}>Category Type</h5>{" "}
                              <p
                                style={{ fontWeight: "500", fontSize: "13px" }}
                                className="mb-1"
                              >
                                {selectadedata.category_name}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex flex-row py-1 justify-content-center ">
                            <div
                              className="row p-3 text-center col-md-6 container"
                              style={{ backgroundColor: "#ebedef" }}
                            >
                              <h5 style={{ fontWeight: "650" }}>City</h5>{" "}
                              <p
                                style={{ fontWeight: "500", fontSize: "13px" }}
                                className="mb-1"
                              >
                                {selectadedata.city_name}
                              </p>
                            </div>

                            <div className="row bg-white p-3 text-center col-md-6 container">
                              <h5 style={{ fontWeight: "650" }}>Area</h5>{" "}
                              <p
                                style={{ fontWeight: "500", fontSize: "13px" }}
                                className="mb-1"
                              >
                                {selectadedata.area_name}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex flex-row py-1 justify-content-center ">
                            <div
                              className="row  p-3 text-center col-md-6 container"
                              style={{ backgroundColor: "#ebedef" }}
                            >
                              <h5 style={{ fontWeight: "650" }}>Rent</h5>{" "}
                              <p
                                style={{ fontWeight: "500", fontSize: "13px" }}
                                className="mb-1"
                              >
                                {selectadedata.rent}
                                {
                                  PerDay && (
                                    <strong className="mx-1">/ Per Day</strong>
                                  )
                                }
                              </p>
                            </div>

                            <div className="row bg-white p-3 text-center col-md-6 container ">
                              <h5 style={{ fontWeight: "650" }}>Furniture Type</h5>{" "}
                              <p
                                style={{ fontWeight: "500", fontSize: "13px" }}
                                className="mb-1"
                              >
                                {selectadedata.furniture_type}

                              </p>
                            </div>
                          </div>
                          <div className="d-flex  py-1 justify-content-center ">
                            <div
                              className="  p-3 px-0 text-center col-md-12 container"
                              style={{ backgroundColor: "#ebedef" }}
                            >
                              <h5 style={{ fontWeight: "650" }}>Amenities</h5>{" "}
                              <p
                                style={{ fontWeight: "500", fontSize: "13px" }}
                                className="mb-1"
                              >
                                {selectadedata.admin_remark}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="d-flex  flex-column modal-forms my-3 mb-0 container justify-content-center">
                            <div className="row">
                              <div className="col-md-6 my-2">
                                <label
                                  for="exampleInputEmail1"
                                  className="form-label"
                                >
                                  Name
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="Enter Your Name"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  value={name}
                                  onChange={(e) => {
                                    setname(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="col-md-6 my-2 mb-0">
                                <div className="form-outline">
                                  <label className="form-label" for="typeNumber">
                                    Number{" "}
                                  </label>

                                  <input
                                    type="number"
                                    id="typeNumber"
                                    placeholder="Enter Your Number"
                                    className="form-control"
                                    value={number}
                                    onChange={(event) => {

                                      const limitedNumber = event.target.value.slice(0, 10);  // Limit to 10 characters
                                      setnumber(limitedNumber);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex  flex-column modal-forms my-3 container justify-content-center">
                            <div className="row">
                              <div className="col-md-6 my-2">
                                <div className="form-outline">
                                  <label className="form-label" for="typeNumber">
                                    Shifting Date{" "}
                                  </label>
                                  <DatePicker
                                    className="form-control"
                                    selected={selectedDate}
                                    onChange={(date) => setSelectedDate(date)}
                                    minDate={new Date()} // Set the minimum date to today

                                  />{" "}
                                </div>
                              </div>
                              <div className="col-md-6 my-2 "></div>
                            </div>
                          </div>

                          <div className="buttonss my-4 mb-5 pb-5" >
                            <button
                              onClick={() => {
                                SendData();

                              }}

                              style={{ backgroundColor: submitButtonColor }}
                              disabled={formSubmitted}
                            >
                              {formSubmitted ? " Submitted" : "Interested"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row videosform section col-md-12">
          <div className="col-md-3 my-2 mb-md-5 mb-4 " id="">
            <div className="form col-md-12  ">
              <div id="mobileinner">
                <div className="container px-2 maindiv ">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    City
                  </label>
                  <div className="City">
                    <Select
                      className=""
                      id=""
                      placeholder="Select Category"
                      options={CityData.map((City) => ({
                        label: City.name,
                        value: City.cityId,
                      }))}
                      value={
                        selectedCityId
                          ? {
                            label: CityData.find(
                              (city) => city.cityId === selectedCityId
                            )?.name,
                            value: selectedCityId,
                          }
                          : null
                      }
                      onChange={handleCityChange}
                    />
                  </div>
                </div>

                <div className="container px-2 maindiv">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Product
                  </label>
                  <div className="  cityp ">
                    <div className="">
                      <Select
                        className=""
                        id=""
                        isMulti={false} // Set to false for single selection
                        placeholder="Select Product"
                        options={category.map((name) => ({
                          label: name.name,
                          value: name.categoryId,
                        }))}
                        onChange={handleSelectChange}
                        value={
                          categoryIds
                            ? {
                              label: category.find(
                                (name) => name.categoryId === categoryIds
                              )?.name,
                              value: categoryIds,
                            }
                            : null // Provide null when there is no selection
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div id="">
                <div className="container px-2 maindiv mt-1 ">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label mb-1"
                  >
                    Area
                  </label>
                  <div className="Areaselectarea">
                    <Select
                      className=""
                      isMulti
                      placeholder="Select Areas"
                      options={Areadata.map((areadata) => ({
                        label: areadata.name,
                        value: areadata.areaId,
                      }))}
                      onChange={(selectedOptions) =>
                        handleAreaChange(selectedOptions)
                      }
                      value={Areadata.filter((areadata) =>
                        Array.isArray(SelectedAreaId)
                          ? SelectedAreaId.includes(areadata.areaId)
                          : SelectedAreaId === areadata.areaId
                      ).map((areadata) => ({
                        label: areadata.name,
                        value: areadata.areaId,
                      }))}
                    />
                  </div>
                </div>
                <div className=" col-md-12 px-2 maindiv towmaindiv mt-2">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label mb-1"
                  >
                    Budget
                  </label>
                  <Box className="container ">
                    <Slider
                      className="roodt"
                      getAriaLabel={() => "Temperature range"}
                      defaultValue={30}
                      // getAriaValueText={valuetext}
                      valueLabelDisplay="auto"
                      value={value}
                      Slider
                      onChange={handleChange}
                      step={stepAmount}
                      marks
                      min={parseFloat(minAmount)}
                      max={parseFloat(maxAmount)}
                    />
                  </Box>
                  <div
                    className="container-fluid d-flex justify-content-between maxheight"
                    style={{ maxHeight: "15px" }}
                  >
                    <div>
                      <p>₹ {value[0]}</p>
                    </div>
                    <div>
                      <p>₹ {value[1]}</p>
                    </div>{" "}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center my-1">
                <div className="buttonss d-flex">
                  <button onClick={handleFilter}>Search</button>
                </div>{" "}
              </div>
            </div>
            
            <ContactusinForm category={categoryId.toUpperCase()}/>

          </div>


          <div className="col-md-9 " id="mobilediv">
            <div className="row">
              <div className="col-8 d-flex align-items-center">
                <h3 className="text-start Heading mb-0">
                  {videoFilterData.length >= 0 ? videoFilterData.length : howmany}{" "}
                  Results | For {NameOfCategoerys} Rent
                </h3>
              </div>
              <div className="col-4">
                {" "}
                {/* <div className="mb-3">
              <div className="input-group mb-3">
                <select className="form-select" id="inputGroupSelect01">
                  <option value="" disabled selected>
                    Sort by : Relevance
                  </option>

                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
            </div> */}
              </div>
            </div>
            {videoFilterData.length !== 0 ? (
              <div className="d-flex col-12 videobar justify-content-start p-2 py-1">
                <div className="row">
                  <div className="d-flex col-12 videobar container-fluid">
                    <div className="row py-0 mb-0  mt-0">
                      <InfiniteScroll
                        pageStart={0}
                        loadMore={loadMore}
                        hasMore={visibleData.length < videoFilterData.length}
                        loader={<div key={0} style={{ display: "flex", justifyContent: "center" }} >
                          <iframe src="https://lottie.host/embed/c8fb0cb2-26a4-4514-b1e9-97edfb86de38/Y5o8S6w9eZ.json"></iframe>
                        </div>}
                      >
                        <div className='row h-130'>
                          {visibleData.map((item, index) => (
                            <div
                              className="col-xl-3 col-lg-6 col-md-4 col-4 p-1 modalvideo"
                              key={index}
                              onClick={(e) => {
                                e.stopPropagation();
                                openModal(item);
                              }}
                            >
                              <div
                                className="video-container position-relative"
                                style={{ height: "100%" }}
                              >
                                <img
                                  className="col-12"
                                  src={
                                    item.image !== ""
                                      ? "https://bookmyqrcode.com/home_rental/" +
                                      item.image
                                      : "Assets/image/Group 168.png"
                                  }
                                  alt=""
                                />
                                <div className="col-md-2 p-0  position-absolute top-0 start-0 titelposter d-flex align-items-center justify-content-center">
                                  <p
                                    style={{ fontSize: "12px" }}
                                    className="p-0 m-0  mb-0 text-start p-1 videosTitleid "
                                  >
                                    {item.inventroyId}
                                  </p>
                                </div>
                                <div className="h6-container position-absolute bottom-0 start-0 titelposter d-flex align-items-center justify-content-center">

                                  <h6 className="videosTitle mb-0 p-2 ">
                                    {item.title}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </InfiniteScroll>

                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="col-md-12 text-center d-flex justify-content-center p-4"
                style={{ width: "100%" }}
              >
                <div className="shimmer-container">
                  <dotlottie-player
                    className="lootie"
                    src="https://lottie.host/ce954cf3-ee28-4c87-8f12-73b69d1e2d95/5G93PDLU0Z.json"
                    style={{ maxwidth: "450px", height: "450px" }}
                    background="transparent"
                    speed="1"
                    loop
                    autoplay
                  ></dotlottie-player>
                  <div className="shimmer"></div>
                </div>
              </div>
            )}
            <div className="buttonss d-flex justify-content-center my-4 ">
              <button hidden={videoFilterData.length >= 0 ? true : false}>
                Load More
              </button>
            </div>
            <ToastContainer style={{ fontSize: "14px", zIndex: "9999999" }}
            />
          </div>
        </div>
      </div>
      <div ref={footerRef}>
       <Content otherContent={otherContent}/>

        <Footer />
      </div>
    </div>
  );
};

export default DummyProduct;