import React from "react";

const howItWorksData = [
  {
    imgSrc: "/Assets/image/HowItWorks/1.svg",
    heading: "Search and Add Videos to Cart",
    description:
      "The user searches for rental properties, browses videos, and adds upto 3 videos to the cart.",
  },
  {
    imgSrc: "/Assets/image/HowItWorks/2.svg",
    heading: "Proceed to Checkout",
    description:
      "After adding 3 videos, the user is prompted to view the cart, enters their name and phone number, and proceeds.",
  },
  {
    imgSrc: "/Assets/image/HowItWorks/3.svg",
    heading: "Receive Owners' Contact Details",
    description:
      "The user pays ₹99, and after payment, receives the contact details of the 3 owners both on the screen and via WhatsApp.",
  },
];

function HowItWorks() {
  return (
    <div className="container my-5 my-sm-5 d-flex flex-column align-items-center justify-content-center position-relative">
      <div
        className="position-absolute d-flex top-25 d-none-1434"
        style={{ gap: "15rem", paddingBottom: "2.5rem", zIndex: 10 }}
      >
        <img
          src="/Assets/image/HowItWorks/arrow1.svg"
          alt="arrow"
          className="h-auto w-auto"
          style={{ maxHeight: "5rem" }}
        />
        <img
          src="/Assets/image/HowItWorks/arrow2.svg"
          alt="arrow"
          className="h-auto w-auto"
          style={{ maxHeight: "5rem" }}
        />
      </div>
      <div
        className="display-4 text-center position-relative"
        style={{ zIndex: 0 }}
      >
        Get Owner/Broker's Details
      </div>
      <h5>(In just 3 Simple Steps)</h5>
      <div
        className="d-flex justify-content-between position-relative p-2"
        style={{ zIndex: 0, gap: "2vw" }}
      >
        {howItWorksData.map((item, index) => (
          <div
            className="shadow rounded-4 p-md-4 p-1 text-center vstack align-items-center"
            style={{ maxWidth: "355px", aspectRatio: 1, flex: 1 }}
          >
            <img
              src={item.imgSrc}
              alt="Search Icon"
              className="mx-auto mb-3 mt-4"
              style={{ width: "5vw", minWidth: "2rem", aspectRatio: 1 }}
            />
            <h5 className="card-title fw-semibold mb-2" style={{fontSize: "clamp(12px, 5vw, 24px)"}}>{index + 1}. {item.heading}</h5>
            <p className="card-text d-lg-block d-none">{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HowItWorks;
