import { useCallback, useContext } from "react";
import { wishlistItemCount } from "../../Contexts/WishListContext";

function OtherCourseCard({ item, id, onClick }) {
  const { setData } = useContext(wishlistItemCount);
  const addToWishlist = useCallback(
    (e) => {
      e.stopPropagation();
      setData((prevData) => {
        const newData = new Set(prevData);
        newData.add(item);
        return newData;
      });
    },
    [item, setData]
  );
  return (
    <div
      className="rounded d-flex flex-shrink-0 position-relative"
      style={{ width: "150px" }}
    >
      <button
        onClick={addToWishlist}
        className="position-absolute p-1 rounded-2 overflow-visible"
        style={{
          zIndex: 10,
          backgroundColor: "#FFAA4A",
          border: "none",
          outline: "none",
          transform: "translateY(-50%)",
          right: 4,
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="#FFAA4A"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="lucide lucide-plus"
        >
          <path d="M5 12h14" />
          <path d="M12 5v14" />
        </svg>
      </button>
      <div className="video position-relative rounded-3 overflow-hidden">
        <div
          className="pid position-absolute bg-light m-2 px-2 py-1 rounded"
          style={{ fontSize: 10 }}
        >
          ID: {item.inventroyId}
        </div>
        <img
          src="Assets/image/playbutton2.svg"
          alt=""
          className="position-absolute top-50 start-50"
          style={{ transform: "translate(-50%, -50%)" }}
          width={50}
        />
        <img
          src={
            item.image !== ""
              ? "https://bookmyqrcode.com/home_rental/" + item.image
              : "Assets/image/Group 168.png"
          }
          id={id}
          onClick={onClick}
          alt=""
          className="rounded"
          style={{ width: "100%", objectFit: "cover", height: "100%" }}
        />
      </div>
    </div>
  );
}

export default OtherCourseCard;
