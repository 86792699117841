import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";


export default function Teammember() {
  return (
    <div className="team container">
        <h3 className="text-center">Our Team</h3>
      <br />
      <Swiper watchSlidesProgress={true} slidesPerView={2} className="mySwiper">
        <SwiperSlide>Ramesh</SwiperSlide>
        <SwiperSlide>mithresh</SwiperSlide>
        <SwiperSlide>Hukam</SwiperSlide>
        <SwiperSlide>nisitha</SwiperSlide>
    
      </Swiper>
    </div>
  );
}
