import { toast, ToastContainer } from "react-toastify";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Modal,Fade,Backdrop,Box } from "@mui/material";

const BrokerProfile = (props) => {
  const {open,setOpen} = props
  const brokerId = useSelector((state) => state.brokerId);

  const [name, setname] = useState([]);
  const [number, setnumber] = useState([]);
  const [datebirth, setdatebirth] = useState([]);
  const [feraname, setferaname] = useState([]);
  const [Location, setLocation] = useState([]);
  const [aniiversary, setaniiversary] = useState([]);
  const [Profile, setProfile] = useState([]);
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  // console.log("aniversary", datebirth);

  useEffect(() => {
    // console.log("setProfile broker id", brokerId);
    if(brokerId != null){
        profile();   
    }
  }, [brokerId]);

  const profile = async () => {
    try {
      const data = await axios.post(    
        "https://bookmyqrcode.com/home_rental/api/api.php",
        {
          method: "getData",
          column: "brokerId",
          value: `${brokerId}`,
          table: "broker",
        }
      );

      setProfile(data?.data?.data);
      // console.log("setProfile", data?.data?.data);

      if (data?.data?.data && data.data.data.length > 0) {
        const profileData = data?.data?.data[0];
        setname(profileData.name);
        setnumber(profileData.number);
        setdatebirth(profileData.rera_number);
        setferaname(profileData.firm_name);
        setLocation(profileData.location);
        setaniiversary(profileData.rera_expiry);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  const BrokerDatasend = async (e) => {

    e.preventDefault();

    if (name.length === 0) {
      toast.error("Enter Name", {
        position: "bottom-right",
        autoClose: 4988,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }

    // if (Location.length === 0) {
    //   toast.error("Enter Location", {
    //     position: "bottom-right",
    //     autoClose: 4988,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: false,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "dark",
    //   });
    // }

 

    // if (feraname.length === 0) {
    //   toast.error("Enter Firm Name", {
    //     position: "bottom-right",
    //     autoClose: 4988,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: false,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "dark",
    //   });
    // }

    else {
      const request = {
        method: "setData",
        column: "brokerId",
        value: `${brokerId}`,
        table: "broker",
        data: {
          name: `${name}`,
          firm_name: `${feraname}`,
          rera_number: `${datebirth}`,
          number: `${number}`,
          location: `${Location}`,
          rera_expiry: `${aniiversary}`,
        },
      };

      // console.log("requestasdasdaa", request);

      const data = await axios.post(
        "https://bookmyqrcode.com/home_rental/api/api.php",
        {
          method: "setData",
          column: "brokerId",
          value: `${brokerId}`,
          table: "broker",
          data: {
            name: `${name}`,
            firm_name: `${feraname}`,
            rera_number: `${datebirth}`,
            number: `${number}`,
            location: `${Location}`,
            rera_expiry: `${aniiversary}`,
          },
        }
      );
      if (data.data.status === "Success") {
        setname("");
        setLocation("");
        setferaname("");
        setaniiversary("");
        setdatebirth("")
        toast.success(" Success", {
          position: "bottom-right",
          autoClose: 4988,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setTimeout(()=>{setOpen(false)},2000)
      } else {
        toast.error("Server error!", {
          position: "bottom-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setTimeout(()=>{setOpen(false)},2000)
      }
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  return (
      <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      >
        <Fade in={open}>
          <Box sx={style}>
        <div className="modal-header">
        <h1
          className="modal-title fs-5 "
          style={{ fontWeight: "600" }}
          id="staticBackdropLabel"
        >
          Edit Your Profile
        </h1>
        <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>
      </div>
      {Profile && Profile.length > 0 ? (
        Profile.map((profile) => (
          <form className="row g-3 col-md-12 container p-5 pt-2 ">
            <div className="col-md-6">
              <label for="inputEmail4" className="form-label">
                Name
              </label>
              <input
                type="text"
                placeholder={profile.name}
                className="form-control"
                id="inputEmail4"
                value={name}
                onChange={(e) => {
                  setname(e.target.value);
                }}
              />
            </div>
            <div className="col-md-6">
              <label for="inputNumber" className="form-label">
                Number
              </label>
              <input
                type="number"
                className="form-control form-control2 "
                placeholder={profile.number}
                id="inputNumber"
                value={number}
                readOnly
              />
            </div>
            <div className="col-12">
              <label for="inputAddress" className="form-label">
                Address
              </label>
              <input
                type="text"
                className="form-control"
                id="inputAddress"
                placeholder={profile.location}
                value={Location}
                onChange={(a) => {
                  setLocation(a.target.value);
                }}
              />
            </div>

            <div className="col-md-6">
              <label for="inputBirth" className="form-label ">
                Date of Birth
              </label>
              <input
                type="text"
                className="form-control "
                placeholder={profile.rera_number}
                value={datebirth}
                id="inputBirth"
                onChange={(re) => {
                    setdatebirth(re.target.value);
                  }}
              />
            </div>

            <div className="col-md-6">
              <label for="inputFirmName" className="form-label">
                Firm Name (
                <strong style={{ fontSize: "14px" }}>
                  for channel partner only
                </strong>
                )
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={profile.firm_name}
                id="inputFirmName"
                value={feraname}
                onChange={(fi) => {
                  setferaname(fi.target.value);
                }}
              />
            </div>
            <div className="col-md-6">
              <label for="inputDOA" className="form-label">
                Date of Anniversary
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={profile.rera_expiry}
                id="inputDOA" 
                value={aniiversary}
                onChange={(ex) => {
                  setaniiversary(ex.target.value);
                }}
              />
            </div>

            <div className="buttonss d-flex justify-content-center">
              <button onClick={BrokerDatasend}>
                <p className="mb-0">Post Now</p>
                <div></div>
              </button>
              <ToastContainer />
            </div>
          </form>
        ))
      ) : (
        <div className="text-center p-4">Please wait...</div>
      )}
      </Box>
        </Fade>
      </Modal>
  );
};
export default BrokerProfile;
