import React from 'react'

const Content = (props) => {
  const {otherContent} = props;

  // console.log('other content data--->',otherContent)

  const content = (contentData)=>{
    if(contentData != null){
      return (
        <div dangerouslySetInnerHTML={{__html : contentData}} />
      )
    }
    else{
      return (<div>Other Content Data.</div>)
    }
  }
  return (
    <div className='d-flex p-lg-5 p-md-4 p-sm-3 p-2' style={{height:"auto" ,backgroundColor:"whitesmoke",minHeight:'70vh'}}>
      {content(otherContent)}
    </div>
  )
}

export default Content
