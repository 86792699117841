import React, { memo, useCallback, useEffect, useState } from "react";
import Select from "react-select";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

const FilterForm = memo(
  ({
    filterOptions,
    selectedCityId,
    handleCityChange,
    handleSelectChange,
    SelectedAreaId,
    value,
    handleChange,
    stepAmount,
    minAmount,
    maxAmount,
    handleFilter,
    handleAreaChange,
  }) => {
    const [setOfCategoriesToDisplay, setSetOfCategoriesToDisplay] = useState(
      []
    );

    const addCategoriesToBeDisplayed = useCallback((p_category) => {
      setSetOfCategoriesToDisplay((prevState) => {
        const categoryAlreadyExist = prevState.find(
          (category) => category.categoryId == p_category.categoryId
        );
        console.log(!categoryAlreadyExist);
        if (!categoryAlreadyExist) {
          return [...prevState, p_category];
        }
        return prevState;
      });
    }, []);

    useEffect(() => {
      setSetOfCategoriesToDisplay([]);
      SelectedAreaId.split(",").forEach((areaId) => {
        filterOptions[selectedCityId]?.areas
          .find((area) => area.areaId === areaId)
          ?.categories.forEach((category) =>
            addCategoriesToBeDisplayed(category)
          );
      });
    }, [SelectedAreaId]);
    return (
      <div className="form mx-auto" style={{ width: 350, maxWidth: "100vw" }}>
        <div>
          <div className="container px-2 maindiv ">
            <label htmlFor="exampleFormControlInput1" className="form-label">
              City
            </label>
            <div className="City">
              <Select
                className=""
                id=""
                placeholder="Select City"
                options={Object.keys(filterOptions).map((City) => ({
                  label: filterOptions[City].cityName,
                  value: City,
                }))}
                onChange={handleCityChange}
              />
            </div>
          </div>
          <div className="container px-2 maindiv mt-1 ">
            <label
              htmlFor="exampleFormControlInput1"
              className="form-label mb-1"
            >
              Area
            </label>
            <div className="Areaselectarea">
              <Select
                className=""
                isMulti
                placeholder="Select Area"
                noOptionsMessage={() => "Please select any City to see Areas."}
                options={filterOptions[selectedCityId]?.areas.map(
                  (areadata) => ({
                    label: areadata.name,
                    value: areadata.areaId,
                  })
                )}
                onChange={(selectedOptions) =>
                  handleAreaChange(selectedOptions)
                }
              />
            </div>
          </div>
        </div>
        <div id="">
          <div className="container px-2 maindiv">
            <label htmlFor="exampleFormControlInput1" className="form-label">
              Product
            </label>
            <div className="  cityp ">
              <div className="">
                <Select
                  className=""
                  id=""
                  isMulti={false} // Set to false for single selection
                  placeholder="Select Rental Type"
                  options={setOfCategoriesToDisplay.map((category) => ({
                    label: category.categoryName,
                    value: category.categoryId,
                  }))}
                  noOptionsMessage={() =>
                    "Please select any Area to see categories."
                  }
                  onChange={handleSelectChange}
                />
              </div>
            </div>
          </div>
          <div className=" col-md-12 px-2 maindiv towmaindiv mt-2">
            <label
              htmlFor="exampleFormControlInput1"
              className="form-label mb-1"
            >
              Budget
            </label>
            <Box className="container ">
              <Slider
                className="roodt"
                getAriaLabel={() => "Temperature range"}
                defaultValue={30}
                valueLabelDisplay="auto"
                value={value}
                Slider
                onChange={handleChange}
                step={stepAmount}
                marks
                min={parseFloat(minAmount)}
                max={parseFloat(maxAmount)}
              />
            </Box>
            <div
              className="container-fluid d-flex justify-content-between maxheight"
              style={{ maxHeight: "15px" }}
            >
              <div>
                <p>₹ {value[0]}</p>
              </div>
              <div>
                <p>₹ {value[1]}</p>
              </div>{" "}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center my-1">
          <div className="buttonssdif d-flex">
            <button onClick={handleFilter}>Search</button>
          </div>{" "}
        </div>
      </div>
    );
  }
);

export default FilterForm;
