import React, { memo } from "react";
import Hero from "../Hero/Hero";
import Question from "../Questions/Question";
import Footer from "../Footer/Footer";

const refundPolicies = [
  {
    question: "No Refunds",
    answer:
      "All payments made for rentals, bookings, and any other services offered by Rentout.homes are non-refundable. Once a transaction is completed, we do not offer refunds under any circumstances.",
  },
  {
    question: "Booking Changes and Cancellations",
    answer:
      "Customers are encouraged to review all details of their booking carefully before making a payment. In the event of any changes or cancellations, no refunds will be processed. Please make sure all booking information is accurate at the time of purchase.",
  },
  {
    question: "Service Disruption",
    answer:
      "While we strive to provide uninterrupted services, Rentout.homes will not be responsible for any service disruptions caused by events beyond our control (including but not limited to natural disasters, technical issues, or changes in local regulations). Refunds will not be provided in these cases.",
  },
  {
    question: "Payment Disputes",
    answer:
      "All payments are final and disputes regarding payments will not be entertained. If you face any issues with our service, we encourage you to contact our support team for assistance.",
  },
  {
    question: "Banking Information",
    answer: [
      "Payments made to Rentout.homes should be directed to the following account:",
      "Account Name: Smart Solutions ",
      "Bank: ICICI Bank, Sector 56, Gurgaon",
    ],
  },
  {
    question: "Contact Us",
    answer: [
      "For any inquiries regarding payments or services, please reach out to our customer support at:",
      " [contact details: 9899142130].",
    ],
  },
];

const RefundPolicy = memo(() => {
  return (
    <div>
      <Hero title="Refund Policy" subTitle="Home/Refund Policy" />
      <Question
        questionList={refundPolicies}
        title="No Refund Policy"
        subTitle="At Rentout.homes, we are committed to providing high-quality service for all our clients. By using our services, you agree to the following terms:"
      />
      <Footer />
    </div>
  );
});

export default RefundPolicy;
