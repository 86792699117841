import React, {
  memo,
} from "react";

const VideoCard = memo(({ item, openModal, className }) => {
  return (
    <div
      className={`overflow-hidden p-3 d-flex flex-column gap-3 rounded-4 shadow bg-light ${className}`}
      onClick={(e) => {
        e.stopPropagation();
        openModal(item);
      }}
    >
      <div className="video position-relative rounded-3 overflow-hidden">
        <div className="pid position-absolute bg-light m-2 px-2 py-1 rounded">
          ID: {item.inventroyId}
        </div>
        <img
          src="Assets/image/playbutton2.svg"
          alt=""
          className="position-absolute top-50 start-50"
          style={{ transform: "translate(-50%, -50%)" }}
          width={50}
        />
        <img
          src={
            item.image !== ""
              ? "https://bookmyqrcode.com/home_rental/" + item.image
              : "Assets/image/Group 168.png"
          }
          alt=""
          className="rounded"
          style={{ width: "100%", objectFit: "cover", height: 400 }}
        />
      </div>
      <button
        className="btn text-white"
        // disabled={formSubmitted}
        style={{
          backgroundColor: "#E7A03C",
        }}
        // onClick={() => {
        //   // SendData();
        // }}
      >
        {/* {formSubmitted ? " Submitted" : "Interested"} */}
        View More
      </button>
    </div>
    // <div
    //   className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-4 p-2 modalvideo"
    //   onClick={(e) => {
    //     e.stopPropagation();
    //     openModal(item);
    //   }}
    // >
    //   <div
    //     className="video-container position-relative d-flex align-items-center justify-content-center"
    //     style={{
    //       height: "100%",
    //       borderRadius: "10px",
    //       overflow: "hidden",
    //     }}
    //   >
    //     <img
    //       className=""
    //       src={
    //         item.image !== ""
    //           ? "https://bookmyqrcode.com/home_rental/" + item.image
    //           : "Assets/image/Group 168.png"
    //       }
    //       alt=""
    //     />
    //     <div className="play-button-container">
    //       <img
    //         className="img-fluid play-button"
    //         src="Assets/image/playbutton.png"
    //         alt=""
    //       />
    //     </div>
    //     <div className="col-md-3 col-5 p-0  position-absolute top-0 start-0 titelposter d-flex align-items-center justify-content-center">
    //       <p
    //         style={{ fontSize: "12px" }}
    //         className="p-0 m-0  mb-0 text-start p-2 videosTitleid "
    //       >
    //         {item.inventroyId}
    //       </p>
    //     </div>

    //   </div>
    // </div>
  );
});

export default VideoCard;

// {/* <div className="h6-container position-absolute bottom-0 start-0 titelpostersecond d-flex align-items-center justify-content-center">
//   <div style={{ width: "100%" }}>
//     {isSmallScreen ? (
//       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 320">
//         <path
//           fill="#F39C12"
//           fillOpacity="1"
//           d="M0,64L120,69.3C240,75,480,85,720,74.7C960,64,1200,32,1320,16L1440,0L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
//         ></path>
//         <text
//           x="50%"
//           y="30%"
//           dominant-baseline="middle"
//           text-anchor="middle"
//           fill="white"
//           fontSize="80"
//         >
//           <tspan dy="100">{item.title}</tspan>{" "}
//           {/* Adjust dy to change the padding */}
//         </text>{" "}
//       </svg>
//     ) : (
//       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
//         <path
//           fill="#F39C12"
//           fillOpacity="1"
//           d="M0,64L120,69.3C240,75,480,85,720,74.7C960,64,1200,32,1320,16L1440,0L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
//         ></path>
//         <text
//           x="50%"
//           y="30%"
//           dominant-baseline="middle"
//           text-anchor="middle"
//           fill="white"
//           fontSize="80"
//         >
//           <tspan dy="100">{item.title}</tspan>{" "}
//           {/* Adjust dy to change the padding */}
//         </text>{" "}
//       </svg>
//     )}
//   </div>
// </div>; */}
