import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer/Footer";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { base_url } from "../constants/Service";

const Blog = () => {
  const [data, setData] = useState([]);
  const blogs = useNavigate();
  const pageTitle = "Blog";
  const canonicalUrl = `${base_url}/Blog`;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://bookmyqrcode.com/home_rental/api/api.php",
        {
          method: "getData",
          table: "blog",
        }
      );
      setData(response.data.data);
      console.log("response.data.data", response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="canonical" href={canonicalUrl} />
        <link rel="canonical" href="http://rentout.homes/blog-listing" />
        <meta
          property="og:image"
          content="https://images.pexels.com/photos/326055/pexels-photo-326055.jpeg?auto=compress&cs=tinysrgb&w=600"
        />
      </Helmet>
      <Header />
      <div>
        <div className="text-center p-4">
          <h3 style={{ fontWeight: "600" }}>Blog</h3>
        </div>
        {data.map((map) => (
          <div
            key={map.id}
            className="container d-flex flex-column justify-content-between align-items-center p-4"
          >
            <div
              className="card col-md-12 p-3"
              onClick={() => {
                // Construct the URL dynamically
                const constructedUrl = `/Blog/${map.title}/${map.blogId}`
                  .replace(/ /g, "-")
                  .replace(/\?/g, "");

                // Navigate to the constructed URL
                blogs(constructedUrl, { state: { id: map.blogId } });
              }}
            >
              <div className="col-md-12">
                <div className="col-md-7 d-flex align-items-center">
                  <img
                    className="img-fluid col-md-1 col-2"
                    style={{
                      borderRadius: "50%",
                      height: "60px",
                      width: "60px",
                    }}
                    src={"https://bookmyqrcode.com/home_rental/" + map.image}
                    alt=""
                  />
                  <h5 className="pb-0 mb-0 mx-2">{map.title}</h5>
                </div>
              </div>
              <div className="card-body">
                <blockquote className="blockquote mb-0">
                  <p>{map.short_desc}</p>
                  <p className="blockquote-footer">{map.blog_date}</p>
                </blockquote>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Blog;
