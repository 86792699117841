import React, { memo } from "react";

const Note = memo(({ children }) => {
  return (
    <div
      className="mb-5 p-4 rounded-5 text-light container"
      style={{ backgroundColor: "#E7A03C" }}
    >
      {children}
    </div>
  );
});

export default Note;
