import axios from "axios";
import { FAIR_POCKET_API_URL , FAIR_POCKET_API_KEY} from "../constants/Service";


const FairPocketApi = async (data)=>{
    const parameters = {
        apikey: FAIR_POCKET_API_KEY ,
        name: data.name,
        mobile: data.number,
        email: "", // You may leave it as an empty string if not provided
        project: data.category_name,
        source: "4",
        requirement: `<br><b>Inventory Id:</b> ${data.inventroyId},<br>
          <b>Title:</b> ${data.title},<br>
          <b>Broker Name:</b> ${data.broker_name},<br>
          <b>City Name:</b> ${data.city_name},<br>
          <b>Rent:</b> ${data.rent},<br>
          <b>Furniture Type:</b> ${data.furniture_type},<br>
          <b>Amenities:</b> ${data.admin_remark}`,
    }

    try{
       await axios.post(FAIR_POCKET_API_URL,parameters)
        .then((response)=>{
            console.log('FairPocket API Data Submitted!')
        }).catch((err)=>{
            console.log('FairPocket API Error--->',err)
        })
    } catch(err){
        console.log('FairPocket API Error--->',err)
    }
}

export {FairPocketApi}