import React, { memo } from "react";
import logo from "../../assets/images/logo.png";
import linkedin from "../../assets/icons/Linkedin.svg";
import fb from "../../assets/icons/Fb.svg";
import insta from "../../assets/icons/Insta.svg";
import pt from "../../assets/icons/Pt.svg";
import { Link } from "react-router-dom";

const socialLinks = [
  {
    icon: linkedin,
    reference: "",
  },
  {
    icon: fb,
    reference: "",
  },
  {
    icon: insta,
    reference: "",
  },
  {
    icon: pt,
    reference: "",
  },
];

const links = [
  {
    text: "Home",
    reference: "/",
  },
  {
    text: "About Us",
    reference: "/aboutus",
  },
  {
    text: "Reach Us",
    reference: "/Reachus",
  },
  {
    text: "Blog",
    reference: "/Blog",
  },
];

const Footer = memo(() => {
  return (
    // <footer className="flex p-10 bg-[#252525] text-white justify-between flex-wrap gap-8">
    //   <div className="space-y-4 max-w-[586px]">
    //     <img src={logo} alt="Rent-out" className="w-20" />
    //     <p className="text-lg">
    //       Whether you're a tenant looking for the perfect place to call home or
    //       a landlord seeking guidance in residential renting, I'm here to help!
    //       Feel free to ask any questions or share your specific needs, and let's
    //       navigate the world of real estate together. 🏡✨
    //     </p>
    //   </div>
    //   {/* <div className="flex gap-4 flex-1 justify-between"> */}
    //   <div className="links space-y-2">
    //     <h3 className="text-lg font-bold">Quick Links</h3>
    //     {links.map((link, index) => (
    //       <p className="block" key={index}>
    //         {link}
    //       </p>
    //     ))}
    //   </div>
    //   <div className="legalLinks space-y-2">
    //     <h3 className="text-lg font-bold">Legal</h3>
    //     <p>Terms & Conditions</p>
    //     <p>Privacy Policy</p>
    //   </div>
    //   {/* </div> */}
    //   <div className="socialLinks space-y-2">
    //     <h3 className="text-lg font-bold">Follow Us On</h3>
    //     <div className="flex gap-4">
    //       {socialLinks.map((link, index) => (
    //         <a href={link.reference} key={index}>
    //           <img src={link.icon} alt={link} className="w-8" />
    //         </a>
    //       ))}
    //     </div>
    //   </div>
    // </footer>
    <footer
      className="d-flex flex-wrap justify-content-between bg-dark text-white gap-5"
      style={{ padding: 40 }}
    >
      <div style={{ maxWidth: "586px" }}>
        <img src={logo} alt="Rent-out" className="w-25 mb-4" />
        <p className="fs-5">
          Whether you're a tenant looking for the perfect place to call home or
          a landlord seeking guidance in residential renting, I'm here to help!
          Feel free to ask any questions or share your specific needs, and let's
          navigate the world of real estate together. 🏡✨
        </p>
      </div>

      <div className="d-flex flex-column g-2">
        <h3 className="fw-bold">Quick Links</h3>
        {links.map((link, index) => (
          <Link
            className="mb-1 text-light ps-1"
            to={link.reference}
            style={{ fontSize: 16 }}
            key={index}
          >
            {link.text}
          </Link>
        ))}
      </div>

      <div className="mb-4 d-flex flex-column gap-1">
        <h3 className="h5 fw-bold">Legal</h3>
        <Link className="mb-1 text-light ps-1" to="/tAndC">Terms & Conditions</Link>
        <Link className="mb-1 text-light ps-1" to="/privacyPolicy">Privacy Policy</Link>
        <Link className="mb-1 text-light ps-1" to="/refundPolicy">Refund Policy</Link>
      </div>

      <div className="mb-4">
        <h3 className="h5 fw-bold">Follow Us On</h3>
        <div className="d-flex gap-3">
          {socialLinks.map((link, index) => (
            <a href={link.reference} key={index}>
              <img src={link.icon} alt={link} width={32} />
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
});

export default Footer;
