const base_url = 'https://rentout.homes'
const API_BASE_URL = 'https://bookmyqrcode.com/home_rental/api/api.php'
const FAIR_POCKET_API_URL = 'https://www.fairpockets.com/ApiIntegration/getdataapi'
const FAIR_POCKET_API_KEY = '8880a70789ada62399a7b0d12a774197'
const BIZ_MAGNET_API_URL = 'https://api.bizmagnets.ai/dev/message/sendTemplateMessage'
const BIZ_MAGNET_API_KEY = '9a005823e25732ff844812df387754a0'
const BIZ_MAGNET_API_CHANNEL_ID = '9d562f63-cd86-3049-bc8a-f1316a2c6cb1'
const BIZ_MAGNET_API_TEMPLATE_ID_BROKER = '30fuQSTACkJb8UzoHxnBWT'
const BIZ_MAGNET_API_TEMPLATE_ID_CLIENT = 'wMU5Rn68qlFhzlkCDZu2WT'

export {
    base_url,
    API_BASE_URL,
    FAIR_POCKET_API_URL,
    FAIR_POCKET_API_KEY,
    BIZ_MAGNET_API_URL,
    BIZ_MAGNET_API_KEY,
    BIZ_MAGNET_API_CHANNEL_ID,
    BIZ_MAGNET_API_TEMPLATE_ID_CLIENT,
    BIZ_MAGNET_API_TEMPLATE_ID_BROKER,
};