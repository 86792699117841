
import React from 'react';
import { Collapse } from 'antd';

const accordionJsx = ()=>{
    const data = {
        header : "Choosing RentOut.Homes as your service provider is a decision that brings numerous benefits, making your rental experience seamless and efficient. Here's why RentOut.Homes stands out in the rental market:",
        mainContent : [
        {
            heading: 'Wide Selection of Properties:',
            content: 'RentOut.Homes offers an extensive range of properties, from luxurious homes to budget-friendly apartments. Our diverse listings ensure that you can find a property that suits your preferences and budget, whether you’re looking for a temporary stay or a long-term rental.',
        },
        {
            heading: 'User-Friendly Platform:',
            content: 'Our website is designed with the user in mind, providing an intuitive and straightforward interface. Searching for your ideal rental property is quick and easy, with filters and search options that help you find exactly what you’re looking for without any hassle.',
        },
        {
            heading: 'Comprehensive Property Management:',
            content: 'We offer top-notch property management services, taking care of every detail from maintenance to tenant screening. This ensures that property owners can enjoy peace of mind, knowing their investments are in good hands, and tenants can expect well-maintained and reliable living spaces.',
        },
        {
            heading: 'Transparent Pricing:',
            content: 'At RentOut.Homes, we believe in transparency. Our pricing structure is clear and straightforward, with no hidden fees. This transparency helps build trust and ensures that you know exactly what you’re paying for, avoiding any unpleasant surprises.',
        },
        {
            heading: 'Reliable Customer Support:',
            content: 'Our dedicated customer support team is always ready to assist you. Whether you have questions about a property, need help with the rental process, or require assistance with any issues during your stay, our support team is just a call or click away.',
        },
        {
            heading: 'Commitment to Quality:',
            content: 'We are committed to providing high-quality rental properties and services. Each property is carefully vetted, and we work closely with property owners to maintain high standards. This commitment ensures that renters have a positive experience and property owners receive the best possible service.',
        },
        {
            heading: 'Convenient Location-Based Searches:',
            content: 'With our advanced search options, you can easily find properties based on location, making it simple to find rentals near your work, school, or preferred neighborhood. This feature saves you time and effort in finding a place that fits your lifestyle.',
        },
    ],

    footer : 'Choosing RentOut.Homes means choosing a reliable, efficient, and customer-focused service provider. Whether you are a property owner looking for professional management or a renter seeking the perfect home, RentOut.Homes is your trusted partner in the rental market. Experience excellence with RentOut.Homes and discover the difference quality service makes.' 
}
    return(
        <>
        <div className=''>
            <p>{data.header}</p>

            <ul>
                {data.mainContent.map((item,index)=>(
                <li key={index}><strong>{item.heading}</strong> {item.content}</li>    
                ))}
            </ul>

            <p>{data.footer}</p>
        </div>
        </>
    )
}

const items = [
    {
        key: '1',
        label: 'Why choose us',
        children: accordionJsx(),
    },
];
const Accordian = () =>

<div className='col-md-12 m-4'> 
    
<Collapse accordion items={items} />
</div>

export default Accordian;