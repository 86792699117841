import React, { createContext, memo, useCallback, useState } from "react";

export const wishlistItemCount = createContext();

const WishListContext = memo(({ children }) => {
  const [data, setData] = useState(new Set());
  return (
    <wishlistItemCount.Provider value={{ data, setData }}>
      {children}
    </wishlistItemCount.Provider>
  );
});

export default WishListContext;
