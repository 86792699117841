import React from "react";

const PrimaryBtn = ({ children, onClick, className, disabled, style }) => {
  return (
    <button
      className={className + " btn text-white"}
      disabled={disabled}
      style={{ backgroundColor: "#E7A03C", width: "50%", ...style }}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default PrimaryBtn;
