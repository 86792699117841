import axios from "axios";
import {
  BIZ_MAGNET_API_KEY,
  BIZ_MAGNET_API_URL,
  BIZ_MAGNET_API_CHANNEL_ID,
  BIZ_MAGNET_API_TEMPLATE_ID_BROKER,
  BIZ_MAGNET_API_TEMPLATE_ID_CLIENT,
} from "../constants/Service";

const BizMagnetApi = async (data) => {
  const headers = {
    "Content-Type": "application/json",
    "X-API-KEY": BIZ_MAGNET_API_KEY,
  };
  const parameters = {
    channelId: BIZ_MAGNET_API_CHANNEL_ID,
    recipient: {
      name: data.name,
      mobileNumber: data.number,
    },
    type: "template",
    template: {
      templateId:
        data.data_to_send === "broker"
          ? BIZ_MAGNET_API_TEMPLATE_ID_BROKER
          : BIZ_MAGNET_API_TEMPLATE_ID_CLIENT,
      mediaLink: null,
      templateVariables: [
        {
          componentType: "body",
          text: data.text,
          position: 1,
        },
        {
          componentType: "body",
          text: data.title,
          position: 2,
        },
      ],
    },
  };
  try {
    axios
      .post(BIZ_MAGNET_API_URL, parameters, { headers: headers })
      .then((response) => {
        console.log("Biz Magnet API data Submitted!", response);
      })
      .catch((err) => console.log("Biz Magnet Api Error---->", err));
  } catch (err) {
    console.log("Biz Magnet Api Error---->", err);
  }
};

export { BizMagnetApi };
