import axios from "axios";
import { API_BASE_URL } from "../constants/Service";

const postApiFunction = async (method, table, column, value,orderColumn,orderValue,data, api_url = API_BASE_URL) => {
    try {
        const response = await axios.post(api_url, {
            method: method,
            table: table,
            ...(column !== null && {column : column}),
            ...(value !== null && {value : value}),
            ...(orderColumn !== null && {orderColumn : orderColumn}),
            ...(orderValue !== null && {orderValue : orderValue}),
            ...(data !== null && {data : data})
        });
        // console.log('called----->',response)
        return response.data; // Ensure you're returning the data or response as needed
    } catch (error) {
        console.error('Error---->', error);
        throw error; // Re-throwing the error might be useful for higher-level error handling
    }
};

export {postApiFunction}